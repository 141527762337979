import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100vh',
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    //alignItems: 'center',
    height: '100vh',
  },
  
  content: {
    textAlign: 'center',
  },
  title: {
    marginBottom: theme.spacing(2),
  },

  
 
  button: {
    margin: theme.spacing(1),
    backgroundColor: green[500],
    color: 'white',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}));

const TeacherPage = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <div className={classes.root}>
      <Button className='backbutton' onClick={() => navigate(-1)}>Voltar</Button>
        <Typography variant="h4" className={'title'}>
          Área da Empresa
        </Typography>
        
        <Button component={Link} to="/notifications" variant="contained" color="primary" className={classes.button} onClick={()=>{}}>
          Notificações
        </Button>
        
        
      </div>
      
      
    
    </Container>
  );
};

export default TeacherPage;
