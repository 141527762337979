import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { connect, ConnectedProps } from 'react-redux';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from './Title';

import { getProdutos, getProdutosByCategory, updateProduto, setProdutoId,setProduto } from '../../redux/produtos/actions';
import {  number } from 'react-admin';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { Zoom } from '@mui/material';
function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}
type PropsFromRedux = ConnectedProps<typeof connector>;

const Itens:React.FC<PropsFromRedux> = ({  isLoadingProdutos, produtos, getProdutos})=> {
  const navigate = useNavigate()
  const zero = (n:any)=>{
    if(n<10)
     {return '0'+n} else return(n)

  }
  const getDate = ()=>{
    const date = new Date()
    const newDate = zero(date.getDate()) + '/' + zero(Number(date.getMonth())+1) + '/' + date.getFullYear()
    return newDate
  }
  return (
    <React.Fragment>
      <Title>Itens </Title>
      <Typography component="p" variant="h2" >
        {produtos.length}
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        em {getDate()}
      </Typography>
      <div>
        <Tooltip TransitionComponent={Zoom}   title="Ítens" arrow>
           <Button color="primary" onClick={()=>{navigate('/produtos')}}>
          Ver itens
        </Button>
        </Tooltip>
       
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state: any) => ({
  usuarios: state.usuarios.usuarios, 
  empresas: state.empresas.empresas,
  produtos: state.produtos.produtos,
  categorias: state.categorias.categorias,
  isLoadingUsuarios : state.usuarios.isLoadingUsuarios,
  isLoadingProdutos : state.produtos.isLoadingProdutos,
});

const mapDispatchToProps = {
  
  getProdutos,
  getProdutosByCategory,
  
  
};

const connector = connect(mapStateToProps, mapDispatchToProps);
  
  export default connector(Itens);