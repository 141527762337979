import React, { ChangeEvent, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { Button, Container, Typography, List, ListItem, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Modal } from '@material-ui/core';
import Fade from '@mui/material/Fade';
import Zoom from '@mui/material/Zoom';
import Tooltip from '@mui/material/Tooltip';
import { connect, ConnectedProps } from 'react-redux';
import { getReferencias, getReferenciaById, getImagesByReferencia,  deleteReferencia, createReferencia, updateReferencia } from '../redux/referencias/actions';
import { fetchEmpresas } from '../redux/empresas/actions';
import { getCategorias } from '../redux/categorias/actions';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUpload from '@material-ui/icons/CloudUpload';
import EditIcon from '@material-ui/icons/Edit';
import ImageSearch from '@material-ui/icons/ImageSearch';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import QrCode from '@mui/icons-material/QrCode';
import ViewHeadline from '@material-ui/icons/ViewHeadline';
import {  InputAdornment } from '@material-ui/core';
import { Email, Phone, Business } from '@material-ui/icons';
import { getEmpresas } from '../api';
import { green } from '@material-ui/core/colors';
import LinearProgress from '@mui/material/LinearProgress';
import Notifications from './../components/notifications/Notifications';
import Box from '@mui/material/Box';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbar,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridValueGetterParams,
  GridToolbarExport,
  useGridApiRef,
} from '@mui/x-data-grid';

import { Autocomplete, Checkbox, Divider, FormControlLabel, FormGroup, ListItemButton, ListSubheader, Stack } from '@mui/material';

import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { DefaultizedPieValueType } from '@mui/x-charts';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';

import {
  randomCreatedDate,
  randomTraderName,
  randomId,
  randomArrayItem,
} from '@mui/x-data-grid-generator';

import Barcode from 'react-barcode';


import {serverConfig} from './../api/apiConfig';
import { updateProdutos } from '../redux/produtos/actions';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    textAlign: 'center',
  },
  container: {
    width:500,
    display: 'flex',
    justifyContent: 'center',
    //alignItems: 'center',
    height: '100%',
  },
  content: {
    textAlign: 'center',
  },
  title: {
    color:'#e8ca74',
    marginBottom: theme.spacing(2),
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    
  },
  
  addButton: {
    margin: theme.spacing(1),
    backgroundColor: green[500],
    color: 'white',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  information: {
    color:'white',
    padding:20,
    marginBottom: theme.spacing(2),
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    minWidth:400,
    minHeight:200,
  },
  text:{
    
    color:'#fff'
  },
  textLength:{
    fontSize:18,
    color:'#efdba0'
  },
  listContainer: {
    maxHeight: 'calc(100vh - 250px)',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'green',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: 'darkgreen',
    },
  },
  select: {
    '&:before': {
        borderColor: 'white',
    },
    '&:after': {
        borderColor: 'white',
    }
},
icon: {
    fill: 'white',
},

formControl: {
  margin: theme.spacing(1),
  width: 500
},
indeterminateColor: {
  color: "green"
},
selectAllText: {
  fontWeight: 500
},
selectedAll: {
  backgroundColor: "rgba(0, 0, 0, 0.08)",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.08)"
  }
}
}));

const produtoType = {
  "nome": "string",
  "empresa": "string",
  "quantidade": 0,
  "minimo": 0,
  "categoria": "string",
  "venda": 0,
  "compra": 0,
  "identificacao": [
    {
      "tipo": "string",
      "codigo": "string"
    }
  ],
  "ativo": true,
  "descricao": "string"
}





type PropsFromRedux = ConnectedProps<typeof connector>;

const ReferenciasPage: React.FC<PropsFromRedux> = ({produtoId,getImagesByReferencia,updateProdutos,imagesByReferencia,usuarios, usuario, categorias, categories, getCategorias, empresas, isLoadingReferencias,isLoadingReferenciaUpdate, isLoadingReferenciasDelete, isLoadingProdutosUpdateMany, referencias, getReferencias, deleteReferencia, createReferencia, updateReferencia}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false);
  const [deleteConfirmationItensOpen, setDeleteConfirmationItensOpen] = React.useState(false);
  const [selectedReferencia, setSelectedReferencia] = React.useState('');
  const [addReferenciaModalOpen, setAddReferenciaModalOpen] = React.useState(false);
  const [newReferenciaName, setNewReferenciaName] = React.useState('');
  const [newReferenciaQuantidade, setNewReferenciaQuantidade] = React.useState<any>(0);
  const [newReferenciaCategoria, setNewReferenciaCategoria] = React.useState('');
  const [newReferenciaMinimo, setNewReferenciaMinimo] = React.useState<any>(0);
  const [newReferenciaCompra, setNewReferenciaCompra] = React.useState<any>(0);
  const [newReferenciaVenda, setNewReferenciaVenda] = React.useState<any>(0);
  const [newReferenciaTelefone, setNewReferenciaTelefone] = React.useState('');
  const [newReferenciaEmpresa, setNewReferenciaEmpresa] = React.useState('');
  const [newReferenciaDescricao, setNewReferenciaDescricao] = React.useState('');
  const [anotation, setAnotation] = React.useState('');
  const [contador, setContador] = React.useState(0);
  const [selectedCategoria, setSelectedCategoria] = React.useState('');
  const [editReferenciaData, setEditReferenciaData] = React.useState<any>({});
  const [modalReferenciaData, setModalReferenciaData] = React.useState<any>({});
  const [referenciasList, setReferenciasList] = React.useState<any>([]);
  const [editReferenciaModalOpen, setEditReferenciaModalOpen] = React.useState(false);
  const [passwordOpen, setPasswordOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [save, setSave] = React.useState(false);
  const [barcode, setBarcode] = React.useState<any>(null);
  const [barcodeTipo, setBarcodeTipo] = React.useState<any>(null);
  const [categoriasToShow, setCategoriasToShow] = React.useState([{nome:'Todas',_id:0}]);
  
  const [rows, setRows] = React.useState(referencias);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [selectedUser, setSelectedUser] = React.useState<any>({key:0,value:'Filtro por operador'});
  
  const [selectedCategory, setSelectedCategory] = React.useState<any>({key:0,value:'Filtro por unidade'});
  const [startDate, setStartDate] = React.useState<any>(null);
  const [endDate, setEndDate] = React.useState<any>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const [imageToOpen, setImageToOpen] = React.useState<any>('');
  const [openModalItens, setOpenModalItens] = React.useState<boolean>(false);
  const [itensList, setItensList] = React.useState<any[]>([]);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);
  const [file, setFile] = React.useState<File>();

  const isAllSelected =
  itensList.length > 0 && selected.length === itensList.length;



  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };
  const apiRef = useGridApiRef();
  const hiddenFields = ['_id','id','ID'];

  const getTogglableColumns = (columns: GridColDef[]) => {
    console.log('getTogglableColumns',columns)
    return columns
      .filter((column) => !hiddenFields.includes(column.field))
      .map((column) => column.field);
  };
  useEffect(() => {
    
  }, [apiRef]);
  useEffect(() => {
    console.log('editReferenciaData', editReferenciaData)
  }, [editReferenciaData]);
  let imagesList:any = [];
  useEffect(() => {
    console.log("imagesByReferencia", imagesByReferencia)
    imagesList = [];
    let cont = 0
    const referenciasListLocal = referenciasList.slice()
    imagesByReferencia.map((item:any, index:any)=>{
      //cont = (cont < item.contador ? item.contador : cont)
      const slide = {
        key: item.idReferencia,
        title: 'Greentag',
        text: '',
        image:serverConfig.dev.url + 'imagens/imagem/'+item._id,
        backgroundColor: 'green',
      }
      
      referenciasListLocal.map((ele:any,index:any)=>{
        if(ele._id == item.idReferencia){
          
          referenciasListLocal[index] = {...ele, imagem : slide.image, contador:item.contador}
        }
  
      })

      imagesList.push(slide)
    })
    setReferenciasList(referenciasListLocal)
    console.log('referenciasListLocal ==>>> ', referenciasListLocal)
    if(referenciasListLocal?.length){
      setRows(referenciasListLocal)
      apiRef.current.setColumnVisibility('_id', false);
    }
    //setContador(cont)
    //setSlides(imagesList)
   
  },[imagesByReferencia]);

  useEffect(() => {
    
      setAddReferenciaModalOpen(false)
      clearNewReferencia()
      getReferencias();
    
    //console.log("getReferencias ====> ", referencias)
  }, [getReferencias]);

  useEffect(() => {
    if(referencias.length){
      setReferenciasList([...referencias])
      getImagesByReferencia(0)
    }
     console.log("setReferenciasList ====> ", referencias)
  }, [referencias]);
    
  useEffect(() => {
    /* if(referenciasList?.length){
      setRows(referenciasList)
      apiRef.current.setColumnVisibility('_id', false);
    } */
  },[referenciasList])
  useEffect(() => {
    fetchEmpresas();
    console.log('empresas' , empresas)
  }, [fetchEmpresas]); 
  useEffect(() => {
    if(save){
      handleUpdateReferencia()
      setSave(false)
    }
    console.log('rows' , rows)
  }, [rows]); 
  useEffect(() => {
    getCategorias();
    console.log('categorias' , categorias)
  }, [getCategorias]); 
  useEffect(() => {
    changeFilter()
    console.log('categorias' , categorias)
  }, [selectedUser,startDate, endDate, selectedCategory]); 
  useEffect(() => {
    if(categorias&&categorias.length&&categoriasToShow.length==1){
      setCategoriasToShow([categoriasToShow[0], ...categorias])
    }
    console.log('categoriasToShow',categoriasToShow)
  }, [categorias]); 

  useEffect(() => {
    if(isLoadingReferencias){
      setLoading(true)
    }
    if(loading && !isLoadingReferencias){
      //getReferencias();
      setLoading(false)
    }
    console.log('isLoadingReferencias ==> ',isLoadingReferencias)
    
  }, [isLoadingReferencias]);
  useEffect(() => {
    if(isLoadingProdutosUpdateMany){
      setLoading(true)
    }
    if(loading && !isLoadingProdutosUpdateMany){
      getReferencias();
      setLoading(false)
    }
    console.log('isLoadingProdutosUpdateMany ==> ',isLoadingProdutosUpdateMany)
    
  }, [isLoadingProdutosUpdateMany]);
  useEffect(() => {
    if(isLoadingReferenciaUpdate){
      setLoading(true)
    }
    if(loading && !isLoadingReferenciaUpdate){
      getReferencias();
      setLoading(false)
    }
    console.log('isLoadingReferenciaUpdate ==> ',isLoadingReferenciaUpdate)
    
  }, [isLoadingReferenciaUpdate]);
  useEffect(() => {
    console.log('loading ====>>> ',loading)
    if(isLoadingReferenciasDelete){
      setLoading(true)
    }
    if(loading && !isLoadingReferenciasDelete){
      getReferencias();
      //changeCategoria( selectedCategoria)
      setLoading(false)
    }
    console.log('isLoadingReferenciasDelete ==> ',isLoadingReferenciasDelete)
    
  }, [isLoadingReferenciasDelete]);

  const checkImage = (key:any)=>{
    const image = imagesList.filter((item:any)=>item.key == key)
    if(image.length)
    return image[0].image
  }

  
  const sendNotification = ()=>{
    const notData = {
      type : 'success',
      message : '',
      title : 'Greentag',
      timeOut : 3000,
      callback : ()=>{},
      priority : true,
    }
    Notifications(notData)
  } 

  const handleDeleteClick = (produtoId: any) => () => {
    setSelectedReferencia(produtoId);
    setDeleteConfirmationOpen(true);
    console.log('produtoId',produtoId)
  };

  const handleDeleteConfirmation = () => {
    deleteReferencia(selectedReferencia);
    setDeleteConfirmationOpen(false);
  };

  const clearNewReferencia =() => {
    setNewReferenciaName('');
    setNewReferenciaMinimo(1);
    setNewReferenciaCompra(0);
    setNewReferenciaVenda(0);
    setNewReferenciaEmpresa('');
    setNewReferenciaQuantidade(0);
    setNewReferenciaCategoria('');
  }

  const handleAddReferencia = () => {
    
    const novaReferencia:any = {
      empresa: newReferenciaEmpresa,
      usuario: usuario._id,
      nome: newReferenciaName,
      descricao : newReferenciaDescricao,
      quantidade: newReferenciaQuantidade||0,
      minimo: newReferenciaMinimo|| 0,
      venda: newReferenciaVenda|| 0,
      compra: newReferenciaCompra|| 0,
      ativo: true,
      itens:[]
    };

    if(file){
      console.log(file)
      novaReferencia.file = file;
      novaReferencia.contador = 1;
    } 
  
    createReferencia(novaReferencia);
    setAddReferenciaModalOpen(false);
    clearNewReferencia()
  };
  
  const zero = (n:any)=>{
    return Number(n) < 10 ? '0'+n : n
  }

  const makeDate = (date:any)=>{
    const data = new Date(date);
    const dia = zero(data.getDate());
    const mes = zero(data.getMonth() + 1);
    const ano = data.getFullYear();
    const label = (`${dia}/${mes}/${ano}`);
    return label
  }

  const handleEditReferencia = (produto:any) => {
    console.log('produto selecionada ==>> ', produto)
    setPasswordOpen(false);
    setNewReferenciaQuantidade('')
    
    setEditReferenciaData(produto);
    setEditReferenciaModalOpen(true);
  };
  const handleLogsReferencia = (prod:any) => {
    const id = prod._id
    console.log('id selecionada ==>> ', id)
    setPasswordOpen(false);
    setNewReferenciaQuantidade('')
    setNewReferenciaCategoria('')
    setSelectedReferencia(id);
  
    setEditReferenciaModalOpen(true);
    navigate('/logs')
  };
  
  const handleUpdateReferencia = () => {
    
    console.log('editReferenciaData handleUpdateReferencia',editReferenciaData)
    const dataToSend = editReferenciaData
    if(anotation){
      dataToSend.dadosAdicionais = {text : anotation}
    }
   
    if(file){
      dataToSend.file = file;
      dataToSend.contador = (editReferenciaData.contador + 1) || 1
    } 
    if(dataToSend.itens) delete dataToSend.itens
    console.log('dataToSend',dataToSend)
    updateReferencia(editReferenciaData)
    setEditReferenciaData({});
    setEditReferenciaModalOpen(false);
    // Lógica para atualizar a Referencia com os dados do modal
  };

  const validatePassword = (password: string | any[]) => {
    return password.length >= 6;
  };
  
  const validateCategoria = (password: any, confirmPassword: any) => {
    return password === confirmPassword;
  };

  const changeCategoria = (cat:any)=>{
    
    console.log('categoria id: ',cat)
    
    if (!cat){
      getReferencias()
    }else{
      //getReferenciasByCategory(cat)
    }
    
  }

 
  

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    const el = rows.filter((item:any)=>item._id == id)
    console.log('handleSaveClick', id)
    console.log('handleSaveClick', el)
    setSave(true)
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
  }
  
  function EditToolbar(props: EditToolbarProps) {
    const { setRows, setRowModesModel } = props;
  
    const handleClick = () => {
      const id = randomId();
      setRows((oldRows) => [...oldRows, { _id : id , nome: '', quantidade: '', identificacao:[{codigo:'', tipo:''}], isNew: true }]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'nome' },
      }));
    };
  
    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Add record
        </Button>
      </GridToolbarContainer>
    );
  }

  const _ = (id: GridRowId) => () => {
    setRows(rows.filter((row:any) => row._id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row:any) => row._id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row:any) => row._id !== id));
    }
  };

  const processRowUpdate:any = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    console.log('newRow',newRow)
    setEditReferenciaData(newRow)
    setRows(rows.map((row:any) => (row._id === newRow._id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const getItens = (id: any) => () => {
    const referencia = rows.filter((item:any)=> id == item._id)[0]
    //console.log('referencia',referencia)
    
    setEditReferenciaData(referencia)
    
    //console.log('editReferenciaData',editReferenciaData)
    setOpenModalItens(true)
    setItensList(referencia.itens)
    
    //handleLogsReferencia(produto[0])
  }

  const ImageComponent = (obj: any)=>{
    //console.log(obj)
    
    if(obj.obj?.value){
      return (
        <div style={{alignSelf:'center'}} onClick={()=>{
          //console.log(obj.obj.value)
          setImageToOpen(obj.obj.value)
          //console.log(imageToOpen)
          handleClick()
        }}>
          <img style={{objectFit:'cover'}} height={60} width={60} src={obj.obj.value} />
        </div>
        
      )
    }else{
      return(
        <IconButton onClick={()=>handleEditReferencia(obj.obj.row)}>
          <ImageSearch />
        </IconButton>
      )
    }
    
  }

  const columns: GridColDef[] = [
    { field: '_id', headerName: 'ID', width: 90 },
    {
      field: 'imagem',
      headerName: 'Imagem',
      width: 80,
      editable: false,
      renderCell: (params) => <ImageComponent obj={params} />, // renderCell will render the component
    },
    {
      field: 'createdAt',
      headerName: 'Data',
      type:'Date',
      width: 110,
      editable: false,
      valueGetter : (params: GridValueGetterParams)=> makeDate(params.row.createdAt)
    },
    {
      field: 'nome',
      headerName: 'Nome',
      width: 140,
      editable: true,
    },
    
    {
      field: 'quantidade',
      headerName: 'Quantidade',
      type: 'number',
      width: 80,
      editable: false,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.itens?.length || 0}  `,
    },
    
    /* {
      field: 'categoria',
      headerName: 'Unidade',
      description: 'unidade do item',
      sortable: false,
      width: 140,
      valueGetter: (params: GridValueGetterParams) =>
        `${getCategoriaNome(params.row.categoria) || ''}  `,
    },  */
    
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Ações',
      width: 200,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
  
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
  
        return [
          <GridActionsCellItem
            icon={<Tooltip TransitionComponent={Zoom}   title="Editar" arrow>
            <IconButton  size='small' className='iconDetail' color="primary" aria-label="Editar" >
              <EditIcon />
            </IconButton>
          </Tooltip>}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<Tooltip TransitionComponent={Zoom}   title="Ver Ítens" arrow>
            <IconButton size='small' className='iconDetail' color="primary" aria-label="Editar" >
              <ViewHeadline />
            </IconButton>
          </Tooltip>}
            label="Ver Itens"
            className="textPrimary"
            onClick={getItens(id)}
            color="inherit"
          />,
        
          <GridActionsCellItem
            icon={<Tooltip TransitionComponent={Zoom}   title="Apagar" arrow>
            <IconButton size='small' className='iconDetail' color="primary" aria-label="Editar" >
              <DeleteIcon />
            </IconButton>
          </Tooltip>}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
    
    
  ];


  const getCategoriaNome = (id:any)=>{
    const nome = categories.filter((item:any)=>id == item._id)
    return nome.length ? nome[0].nome : id

  }

  const changeFilter = ()=>{

    const objToSend = {
      startDate,
      endDate,
      empresa: usuario.empresa,
      categoria:selectedCategory?._id || '',
      usuario : selectedUser?._id || '',
      
    }
    //console.log('objToSend', objToSend)
    //getProductsByFilter(objToSend)
    //getReferenciasByFilter(objToSend)
  
  }

  const handleClick = () => {
    //setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const handleChange = (checked:any,item?:any) => {
    //const value = event.target.value;
    //const checked  = event.target.checked
    //console.log('item  ===== ', item)
    //console.log('checked  ===== ', checked)
    if (item === "all") {
      const list = []
      const idsList = []
      for(let i=0 ; i < itensList.length ; i++){
        list.push(itensList[i].nome)
        idsList.push(itensList[i]._id)
      }
      setSelected(selected.length === itensList.length ? [] : list);
      setSelectedIds(selected.length === itensList.length ? [] : idsList);
      //setSelectedIds( idsList);
      return;
    }
    const itemId = item._id
    const itemNome = item.nome
    //console.log(itemNome)
    //console.log(itemId)
    if (checked){
      setSelected([...selected, itemNome]);
      setSelectedIds([...selectedIds , itemId]);
    } else {
      let list = [...selected]
      list = list.filter((item)=> item != itemNome)
      setSelected([...list]);
      let listIds = [...selectedIds]
      listIds = listIds.filter((item)=> item != itemId)
      //console.log('listIds',listIds)
      setSelected([...listIds]);
    }
  };

  const handleDeleteConfirmationItens = () => {
    //deleteCategoria(selectedCategoria);
    //setDeleteConfirmationOpen(false);
    const dataToSend = {
      'produtosId' : selectedIds,
      'referenciaId' : editReferenciaData._id,
      params : {'referencia' : null}
    }
    console.log('editReferenciaData ==> ', editReferenciaData)
    console.log('selected ==> ',selected)
    console.log('selectedIds ==> ',selectedIds)

    setOpenModalItens(false)
    setDeleteConfirmationItensOpen(false)
    console.log(dataToSend)
    updateProdutos(dataToSend)
    setEditReferenciaData({});
    
  };

  const handleDeleteItem = ()=>{
    setDeleteConfirmationItensOpen(true)
  }
  
  
  return (
    <Container className={classes.container}>
    <Modal open={open} onClick={(evt)=>{
                      setImageToOpen('')
                      handleClick()
                    }}  style={{width:'100%', textAlign: 'center', display: 'flex',
            alignItems : 'center', justifyContent: 'space-between',backgroundColor:'white'}}>
            <Box>
              <Typography>
                clique para fechar
              </Typography>
              <Typography>
                {editReferenciaData.nome}
              </Typography>
               <img width={500} style={{border:'2px solid white', maxHeight:'80vh'}}
                    src={`${imageToOpen}?w=248&fit=crop&auto=format`}
                    srcSet={`${imageToOpen}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={''}
                    loading="lazy"
                   
                  />
            </Box>
          </Modal>
          <Dialog open={deleteConfirmationItensOpen} onClose={() => setDeleteConfirmationItensOpen(false)}>
            <DialogTitle>Confirmar exclusão</DialogTitle>
            <DialogContent>
              <Typography variant="body1">Tem certeza de que deseja desvincular os Ítens?</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDeleteConfirmationItensOpen(false)}>Cancelar</Button>
              <Button onClick={handleDeleteConfirmationItens} color="secondary">
                Confirmar
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={openModalItens} style={{minWidth:600, minHeight:500}} onClose={() =>{
            setItensList([])
            setOpenModalItens(false)
          }}>

        <DialogTitle> {editReferenciaData.nome}</DialogTitle>
        <DialogContent className={classes.modalContent}>
        <Box>
               <FormGroup >
                 <FormControlLabel control={
                  <Checkbox
                          classes={{ indeterminate: classes.indeterminateColor }}
                          checked={isAllSelected}
                          value={'all'}
                          indeterminate={
                            selected.length > 0 && selected.length < itensList.length
                          }
                          onChange={(event)=>{handleChange('','all')}}
                  />} label="Todos" />
                {itensList.length ? (
                  itensList.map(
                    (item:any)=>
                      <div className='text'>
                        <FormControlLabel key={item._id} control={<Checkbox value={item.nome} onChange={(event)=>{handleChange(event.target.checked,item)}} checked={selected.indexOf(item.nome) > -1} />} label={item.nome} />
                      </div>
                  )
                  
                ) : (<div>
              Nenhum ítem encontrado
              </div>) }
            </FormGroup>
               
            </Box>


        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
              setItensList([])
              setOpenModalItens(false)
            }}>Cancelar</Button>
          <Button onClick={handleDeleteItem} style={{color:'red'}}>
            Desvincular Ítens
          </Button>
        </DialogActions>

        </Dialog>
    
            
           

      
      <div className={classes.root}>
          <div className={classes.content}>
          {loading||isLoadingReferencias||isLoadingReferenciaUpdate||isLoadingReferenciasDelete ? (
              <Box sx={{ width: '100%' }}>
                <LinearProgress />
              </Box>
            ) : null
          }
          <Button className='backbutton' onClick={() => navigate(-1)}>Voltar</Button>
          
          <Typography variant="h4" className={'title'}>
            Gerenciar Referencias
          </Typography>
          <Button className={classes.addButton} variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => setAddReferenciaModalOpen(true)}>
            Criar Referência
          </Button>
          {referencias.length?(
            <div className={'text'} style={{margin:15}}>
              {referenciasList.length} Referências encontradas
            </div>
          ):null}


          {/* <Box sx={{  width: '100%', height:180, }} style={{ alignSelf:'center', paddingTop:10, paddingBottom:10, borderColor:'green', borderWidth:2}}>
            <Stack   borderRadius={15} spacing={2} direction="column" alignItems={'center'} alignContent={'center'} divider={<Divider orientation="vertical" flexItem />}>
            <Box sx={{  width: '100%' }} style={{display: 'flex',flexDirection: 'row',flexWrap: 'nowrap',height:30, alignContent: 'center', justifyContent: 'space-around', alignSelf:'center', paddingTop:10, paddingBottom:10, borderColor:'green', borderWidth:2}}>
             
            <Autocomplete
                getOptionLabel={(usu:any) => usu.nome }
                //getOptionSelected={(option:any, value:any) => option.nome === value.nome }
                onChange={(event, newValue) => {
                    console.log(newValue)
                    setSelectedUser(newValue)        
                    // changeFilter()
                    
                }}
                disablePortal
                id="combo-box-usuario"
                options={usuarios}
                sx={{ width: 300 }}
                renderInput={(params:any) => <TextField {...params} label="Operador" />}
              />
            
            </Box>
            <Box sx={{  width: '100%' }} style={{display: 'flex',flexDirection: 'row',flexWrap: 'nowrap',height:70, alignContent: 'center', justifyContent: 'center', alignSelf:'center', paddingTop:10, paddingBottom:10, borderColor:'green', borderWidth:2}}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker', 'DatePicker']}>
                  <DatePicker label="Inicial" 
                          
                          format="DD/MM/YYYY"
                          onChange={(value:any)=>{
                            if(!value) return
                            console.log('inicial',value.$d)
                            setStartDate(value.$d)
                          }} 
                        />
                  <DatePicker label="Final" 
                          format="DD/MM/YYYY"
                          onChange={(value:any)=>{
                            if(!value) return
                            const valueDate = new Date(value.$d)
                            valueDate.setHours(23)
                            valueDate.setMinutes(59)
                            valueDate.setSeconds(59)
                            //console.log('day', day)
                            console.log('final', valueDate)
                            setEndDate(valueDate)
                          }}
                        
                        />
                </DemoContainer>
              </LocalizationProvider>
              <Button className='{classes.irButton}' onClick={() => changeFilter()}>Ir</Button>
            </Box>
            
              

              

              
             
          </Stack>
          
          
          </Box>  */}

         {/*  <FormControl fullWidth>
              <InputLabel className={'text'} htmlFor="tipo-select">Itens por Unidade</InputLabel>
              <Select
                className={classes.select}
                value={selectedCategoria}
                onChange={(e:any) => {

                  setSelectedCategoria( e.target.value )
                  changeCategoria( e.target.value)
                  
                }}
                inputProps={{
                  name: 'categoria',
                  id: 'tipo-select',
                }}
              >
                {categoriasToShow.map((cat: { _id: any; nome: any; }) => (
                  <MenuItem className={'text'} key={cat._id} value={cat._id}>
                    {cat.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
            
            
          {referenciasList.length ? (
            <Box sx={{  width: '100%' }}>
              
            <DataGrid
              apiRef={apiRef}
              loading={isLoadingReferencias}
              getRowId={(row: { _id: any; }) => row._id}
              
              autoHeight={true}
              rows={rows}
              columns={columns}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'createdAt', sort: 'desc' }],
                },
                pagination: {
                  paginationModel: {
                    pageSize: 15,
                  },
                },
              }}
              pageSizeOptions={[5]}
              checkboxSelection
              disableRowSelectionOnClick
              editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              // slots={{
              //   toolbar: EditToolbar,
              // }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: { setRows, setRowModesModel,csvOptions:{
                  fileName: 'itensDataBase_',
                  delimiter: ';',
                  utf8WithBom: true,
                },
                columns: {
                  columnVisibilityModel:{ID: false,},
                },
                columnsPanel: {
                  getTogglableColumns,
                },
                printOptions: {hideToolbar:true, disableToolbarButton: false }},
              }}

              
       
              //toolbar: { printOptions: { getRowsToExport: getSelectedRowsToExport } },
        
        
            />
              
          </Box>
          ):(<div>
            Nehum Item encontrado
          </div>)}
          {/* {referenciasList.length ? (
            <div className={'listContainer'}>
              
              <List >
                {referenciasList.map((produto: any, index: any) => (
                  <ListItem key={index} className={classes.listItem} divider>
                      <div onClick={()=>setModalReferenciaData(produto)}>
                          <Typography className={'text'}>{produto.nome}</Typography>
                          <Typography className={'text'}>{produto.identificacao[0].codigo}</Typography>
                      </div>
                    
                    <div>
                      <Tooltip TransitionComponent={Zoom}   title="Editar" arrow>
                        <IconButton className='iconEdit' color="primary" aria-label="Editar" onClick={() => handleEditReferencia(produto)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip TransitionComponent={Zoom}   title="Movimentações" arrow>
                        <IconButton className='iconDetail' color="primary" aria-label="Editar" onClick={() => handleLogsReferencia(produto)}>
                          <ViewHeadline />
                        </IconButton>
                      </Tooltip>
                      <Tooltip TransitionComponent={Zoom}   title="Apagar" arrow>
                        <IconButton
                          color="secondary"
                          aria-label="Deletar"
                          className="iconDelete"
                          onClick={() => _(produto._id)}
                        >
                          
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                      
                      
                    </div>
                  </ListItem>
                  
                ))}
              </List>

            </div>
            
          ) : <div className={classes.information}>
                Nenhum Item encontrado
              </div>} */}
          <Button className='backbutton' onClick={() => navigate(-1)}>Voltar</Button>
        </div>
      </div>

      <Dialog open={deleteConfirmationOpen} onClose={() => setDeleteConfirmationOpen(false)}>
        <DialogTitle>Confirmar exclusão</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Tem certeza de que deseja excluir este item?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmationOpen(false)}>Cancelar</Button>
          <Button onClick={handleDeleteConfirmation} color="secondary">
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
      
      <Dialog open={addReferenciaModalOpen} onClose={() => setAddReferenciaModalOpen(false)}>
        <DialogTitle>Adicionar Item</DialogTitle>
        <DialogContent className={classes.modalContent}>
          <TextField
            label="Nome do Produto Referencia"
            value={newReferenciaName}
            onChange={(e) => setNewReferenciaName(e.target.value)}
          />
          <TextField
            label="Descrição do Produto Referencia"
            value={newReferenciaDescricao}
            onChange={(e) => setNewReferenciaDescricao(e.target.value)}
          />
          {/* <TextField
            label="E-mail"
            value={newReferenciaMinimo}
            onChange={(e) => setNewReferenciaMinimo(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              ),
            }}
            error={!validateEmail(newReferenciaMinimo)}
            helperText={!validateEmail(newReferenciaMinimo) && 'E-mail inválido'}
          /> */}
          
          {/* <FormControl fullWidth>
              <InputLabel htmlFor="empresa-select">Selecione a Empresa</InputLabel>
              <Select
                value={newReferenciaEmpresa}
                onChange={(e:any) => setNewReferenciaEmpresa( e.target.value )}
                inputProps={{
                  name: 'empresa',
                  id: 'empresa-select',
                }}
              >
                {empresas.map((empresa: { _id: any; nome: any; }) => (
                  <MenuItem key={empresa._id} value={empresa._id}>
                    {empresa.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}

            <div className="file-upload">
              {/* <img src={uploadImg} alt="upload" /> */}
              <CloudUpload />
              <h3> {file?.name || "Clique para escolher uma imagem"}</h3>
              <p>Tamanho máximo do arquivo 10mb</p>
              <input type="file" onChange={handleFileChange} />
            </div>
            <div>{file && `${file.name} - ${file.type}`}</div>

          
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddReferenciaModalOpen(false)}>Cancelar</Button>
          <Button onClick={handleAddReferencia} color="primary">
            Criar
          </Button>
        </DialogActions>
      </Dialog>
      { editReferenciaModalOpen && editReferenciaData.nome ?
      <Dialog maxWidth="lg" open={editReferenciaModalOpen} onClose={() => setEditReferenciaModalOpen(false)}>
        <DialogTitle>Editar Referencia</DialogTitle>
        <DialogContent className={classes.modalContent}>
          <TextField
            label="Nome da Referencia"
            value={editReferenciaData.nome}
            onChange={(e) => setEditReferenciaData({ ...editReferenciaData, nome: e.target.value })}
          />
          
            <TextField
              label="Empresa"
              value={editReferenciaData.empresa}
              onChange={(e) => setNewReferenciaEmpresa(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Business />
                  </InputAdornment>
                ),
              }}
              error={editReferenciaData.empresa == ""}

              helperText={
                editReferenciaData.empresa=="" &&
                'Empresa inválida'
              }
            />


            <div className="file-upload">
              {/* <img src={uploadImg} alt="upload" /> */}
              <CloudUpload />
              <h3> {file?.name || "Clique para escolher uma imagem"}</h3>
              <p>Tamanho máximo do arquivo 10mb</p>
              <input type="file" onChange={handleFileChange} />
            </div>
            <div>{file && `${file.name} - ${file.type}`}</div>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditReferenciaModalOpen(false)}>Cancelar</Button>
          <Button onClick={handleUpdateReferencia} color="primary">
            Atualizar
          </Button>
        </DialogActions>
      </Dialog>:null}

      
{modalReferenciaData.nome ? (
    <Modal open={modalReferenciaData.nome} onClose={() => setModalReferenciaData({})}>
  <div>
 


    <input
      type="text"
      value={modalReferenciaData.nome}
      onChange={(e) => setEditReferenciaData({ ...modalReferenciaData, nome: e.target.value })}
    />
    <input
      type="text"
      value={modalReferenciaData.identificacao[0].codigo}
      onChange={(e) => setEditReferenciaData({ ...modalReferenciaData, nome: e.target.value })}
    />
    <input
      type="text"
      value={modalReferenciaData.quantidade}
      onChange={(e) => setEditReferenciaData({ ...modalReferenciaData, nome: e.target.value })}
    />
    <input
      type="text"
      value={(categorias.find((item:any)=> item._id == modalReferenciaData.categoria))?.nome||''}
      onChange={(e) => setEditReferenciaData({ ...modalReferenciaData, email: e.target.value })}
    />
    <input
      type="text"
      value={(empresas.find((item:any)=> item._id == modalReferenciaData.empresa))?.nome||''}
      onChange={(e) => setEditReferenciaData({ ...modalReferenciaData, telefone: e.target.value })}
    />
    <input
      type="text"
      value={modalReferenciaData.compra}
      onChange={(e) => setEditReferenciaData({ ...modalReferenciaData, telefone: e.target.value })}
    />
    <input
      type="text"
      value={modalReferenciaData.venda}
      onChange={(e) => setEditReferenciaData({ ...modalReferenciaData, telefone: e.target.value })}
    />
    <input
      type="text"
      value={modalReferenciaData.minimo}
      onChange={(e) => setEditReferenciaData({ ...modalReferenciaData, cnpj: e.target.value })}
    />
    <button onClick={handleUpdateReferencia}>Atualizar</button>
  </div>
</Modal>
)
  : null}
    
    </Container>
  );
};

const mapStateToProps = (state: any) => ({
  referencias: state.referencias.referencias , 
  imagesByReferencia: state.referencias.imagesByReferencia , 
  isLoadingReferencias : state.referencias.isLoadingReferencias,
  isLoadingReferenciaUpdate : state.referencias.isLoadingReferenciaUpdate,
  isLoadingReferenciasDelete : state.referencias.isLoadingReferenciasDelete,
  empresas: state.empresas.empresas,
  isLoadingProdutosUpdateMany: state.produtos.isLoadingProdutosUpdateMany,
  categorias: state.categorias.categorias,
  categories : state.categorias.categorias,
  produtoId : state.referencias.produtoId,
  usuarios: state.usuarios.usuarios, 
  usuario : state.user.user,
});

const mapDispatchToProps = {
    
    getCategorias,
    fetchEmpresas,
    getReferencias,
    getImagesByReferencia,
    deleteReferencia,
    createReferencia,
    updateReferencia,
    updateProdutos
    
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ReferenciasPage);
