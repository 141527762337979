import * as api from '../../api';
import RfidApi from '../../api/rfidApi';
import {serverConfig} from '../../api/apiConfig';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';


// Defina o tipo de ação
export const UPDATE_USER_ROLE = 'UPDATE_USER_ROLE';
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';





export const loginStart = (credentials: { email: string; password: string }) => {

  return async (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    const { email, password } = credentials;
    const name = email.split('@')[0];
    const type = 'User';
    try {
      const rfidApi = new RfidApi(null);
      const data = { email, password };

      const response: any = await rfidApi.Fetch({
        method: 'post',
        body: data,
        url: `auth/login`,
      });

      console.log('response login =====>>>', response.data);

      const jwtToken = response.data?.token;
      const renewToken = response.data?.renewToken;

      if(!jwtToken){
        console.log('response login =====>>>', response.response.data);
        dispatch({ type: LOGIN_FAILED , payload : response.response.data});
        return
      }

      console.log('renewToken ========>>>>>>> ', renewToken);

      localStorage.setItem('AUTH_USER_TOKEN_KEY', jwtToken);
      localStorage.setItem('AUTH_USER_RENEW_TOKEN_KEY', renewToken);
      localStorage.setItem('loginData', JSON.stringify(data));

      const getResult = localStorage.getItem('AUTH_USER_TOKEN_KEY');

      console.log('getResult token ======= > ', getResult);

      //dispatch(loginSuccess(response.data));
      dispatch({ type: LOGIN_SUCCESS , payload : response.data});
      return 
    } catch (error) {
      console.log('error loginAsync ', error);
      dispatch({ type: LOGIN_FAILED , payload : error});
    }
  };
};
export const loginSuccess = (user: any) => ({
    type: LOGIN_SUCCESS,
    payload : user
  });
  // Defina a ação para atualizar o user.role
export const updateUserRole = (newRole: any) => {
    return {
      type: UPDATE_USER_ROLE,
      payload: newRole,
    };
  };
export const logout = () => {
    return {
      type: LOGOUT_SUCCESS,
      payload: null,
    };
  };