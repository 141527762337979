import React, { ChangeEvent, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { Button, Container, Typography, List, ListItem, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Modal } from '@material-ui/core';
import Fade from '@mui/material/Fade';
import Zoom from '@mui/material/Zoom';
import Tooltip from '@mui/material/Tooltip';
import { connect, ConnectedProps } from 'react-redux';
import { getProdutos, getProdutosByCategory, getProdutosByFilter, getImagesByProduct, getSelectedImage, deleteProduto, createProduto, updateProduto, setProdutoId,setProduto } from '../redux/produtos/actions';
import { fetchEmpresas } from '../redux/empresas/actions';
import { getImagesByReferencia, getReferencias } from '../redux/referencias/actions';
import { getCategorias } from '../redux/categorias/actions';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUpload from '@material-ui/icons/CloudUpload';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import QrCode from '@mui/icons-material/QrCode';
import ViewHeadline from '@material-ui/icons/ViewHeadline';
import {  InputAdornment } from '@material-ui/core';
import { Email, Phone, Business } from '@material-ui/icons';
import { getEmpresas } from '../api';
import { green } from '@material-ui/core/colors';
import LinearProgress from '@mui/material/LinearProgress';
import Notifications from './../components/notifications/Notifications';
import Box from '@mui/material/Box';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbar,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridValueGetterParams,
  GridToolbarExport,
  useGridApiRef,
} from '@mui/x-data-grid';

import { Autocomplete, Divider, ListItemButton, ListSubheader, Stack } from '@mui/material';

import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { DefaultizedPieValueType } from '@mui/x-charts';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';

import {
  randomCreatedDate,
  randomTraderName,
  randomId,
  randomArrayItem,
} from '@mui/x-data-grid-generator';

import Barcode from 'react-barcode';


import {serverConfig} from './../api/apiConfig';
import ImageSearch from '@material-ui/icons/ImageSearch';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    textAlign: 'center',
  },
  container: {
    width:500,
    display: 'flex',
    justifyContent: 'center',
    //alignItems: 'center',
    height: '100%',
  },
  content: {
    textAlign: 'center',
  },
  title: {
    color:'#e8ca74',
    marginBottom: theme.spacing(2),
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    
  },
  
  addButton: {
    margin: theme.spacing(1),
    backgroundColor: green[500],
    color: 'white',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  information: {
    color:'white',
    padding:20,
    marginBottom: theme.spacing(2),
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  text:{
    
    color:'#fff'
  },
  textLength:{
    fontSize:18,
    color:'#efdba0'
  },
  listContainer: {
    maxHeight: 'calc(100vh - 250px)',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'green',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: 'darkgreen',
    },
  },
  select: {
    '&:before': {
        borderColor: 'white',
    },
    '&:after': {
        borderColor: 'white',
    }
},
icon: {
    fill: 'white',
},
}));

const produtoType = {
  "nome": "string",
  "empresa": "string",
  "quantidade": 0,
  "minimo": 0,
  "categoria": "string",
  "venda": 0,
  "compra": 0,
  "identificacao": [
    {
      "tipo": "string",
      "codigo": "string"
    }
  ],
  "ativo": true,
  "descricao": "string"
}





type PropsFromRedux = ConnectedProps<typeof connector>;

const ProdutosPage: React.FC<PropsFromRedux> = ({produtoId, referencias, setProdutoId, setProduto,imagesByProduct,imagesByReferencia, getReferencias, getImagesByReferencia,usuarios, usuario, categorias, categories, getCategorias, getImagesByProduct, getSelectedImage, empresas, fetchEmpresas, isLoadingProdutos, isLoadingProdutosUpdate, isLoadingProdutosDelete, produtos, getProdutos, getProdutosByCategory, getProdutosByFilter, deleteProduto, createProduto, updateProduto}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false);
  const [selectedProduto, setSelectedProduto] = React.useState('');
  const [addProdutoModalOpen, setAddProdutoModalOpen] = React.useState(false);
  const [newProdutoName, setNewProdutoName] = React.useState('');
  const [newProdutoQuantidade, setNewProdutoQuantidade] = React.useState('');
  const [newProdutoCategoria, setNewProdutoCategoria] = React.useState('');
  const [newProdutoMinimo, setNewProdutoMinimo] = React.useState('');
  const [newProdutoCompra, setNewProdutoCompra] = React.useState('');
  const [newProdutoVenda, setNewProdutoVenda] = React.useState('');
  const [newProdutoTelefone, setNewProdutoTelefone] = React.useState('');
  const [newProdutoEmpresa, setNewProdutoEmpresa] = React.useState('');
  const [newProdutoDescricao, setNewProdutoDescricao] = React.useState('');
  const [anotation, setAnotation] = React.useState('');
  const [contador, setContador] = React.useState(0);
  const [selectedCategoria, setSelectedCategoria] = React.useState('');
  const [editProdutoData, setEditProdutoData] = React.useState<any>({});
  const [modalProdutoData, setModalProdutoData] = React.useState<any>({});
  const [produtosList, setProdutosList] = React.useState<any>([]);
  const [editProdutoModalOpen, setEditProdutoModalOpen] = React.useState(false);
  const [passwordOpen, setPasswordOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [save, setSave] = React.useState(false);
  const [barcode, setBarcode] = React.useState<any>(null);
  const [barcodeTipo, setBarcodeTipo] = React.useState<any>(null);
  const [categoriasToShow, setCategoriasToShow] = React.useState([{nome:'Todas',_id:0}]);
  
  const [rows, setRows] = React.useState(produtos);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [selectedUser, setSelectedUser] = React.useState<any>({key:0,value:'Filtro por operador'});
  
  const [selectedCategory, setSelectedCategory] = React.useState<any>({key:0,value:'Filtro por unidade'});
  const [startDate, setStartDate] = React.useState<any>(null);
  const [endDate, setEndDate] = React.useState<any>(null);

  const [open, setOpen] = React.useState<boolean>(false);
  const [imageToOpen, setImageToOpen] = React.useState<any>('');
  
  const [file, setFile] = React.useState<File>();
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };
  const apiRef = useGridApiRef();
  const hiddenFields = ['_id','id','ID'];

  const getTogglableColumns = (columns: GridColDef[]) => {
    console.log('getTogglableColumns',columns)
    return columns
      .filter((column) => !hiddenFields.includes(column.field))
      .map((column) => column.field);
  };
  useEffect(() => {
    
  }, [apiRef]);

  let imagesList:any = [];
  useEffect(() => {
    console.log("imagesByReferencia", imagesByReferencia)
    imagesList = [];
    let cont = 0
    const produtosListLocal = produtosList.slice()
    imagesByReferencia.map((item:any, index:any)=>{
      //cont = (cont < item.contador ? item.contador : cont)
      const slide = {
        key: item.idReferencia,
        title: 'Greentag',
        text: '',
        image:serverConfig.dev.url + 'imagens/imagem/'+item._id,
        backgroundColor: 'green',
      }
      
      produtosListLocal.map((ele:any,index:any)=>{
        if(ele.referencia?._id == item.idReferencia){
          
          produtosListLocal[index] = {...ele, imagem : slide.image, contador:item.contador}
        }
  
      })

      imagesList.push(slide)
    })
    setProdutosList(produtosListLocal)
    console.log('produtosListLocal ==>>> ', produtosListLocal)
    if(produtosListLocal?.length){
      setRows(produtosListLocal)
      apiRef.current.setColumnVisibility('_id', false);
    }
    //setContador(cont)
    //setSlides(imagesList)
   
  },[imagesByReferencia]);

  useEffect(() => {
    console.log("imagesbyproduct", imagesByProduct)
    const imagesList:any = [];
    let cont = 0
    imagesByProduct.map((item:any, index:any)=>{
      cont = (cont < item.contador ? item.contador : cont)
      const slide = {
        key: index,
        title: 'Greentag',
        text: '',
        image:serverConfig.dev.url + 'imagens/imagem/'+item._id,
        backgroundColor: 'green',
        
      }
      imagesList.push(slide)
    })
    console.log('cont ==>>> ', cont)
    setContador(cont)
    //setSlides(imagesList)
   
  },[imagesByProduct]);

  useEffect(() => {
    setAddProdutoModalOpen(false)
    clearNewProduto()
    getProdutos();
    console.log("getProdutos ====> ", produtos)
  }, [getProdutos]);
  useEffect(() => {
    if(produtos.length){
      setProdutosList(produtos)
    }
    
    //setRows(produtos)
    console.log("setProdutosList ====> ", produtos)
    getImagesByReferencia(0)
  }, [produtos]);
    
  useEffect(() => {
    if(produtosList?.length){
      apiRef.current.setColumnVisibility('_id', false);
    }
  },[produtosList])
  useEffect(() => {
    if(!referencias.length){
      getReferencias()
    }
  },[referencias])
  useEffect(() => {
    fetchEmpresas();
    console.log('empresas' , empresas)
  }, [fetchEmpresas]); 
  useEffect(() => {
    if(save){
      handleUpdateProduto()
      setSave(false)
    }
    console.log('rows' , rows)
  }, [rows]); 
  useEffect(() => {
    getCategorias();
    console.log('categorias' , categorias)
  }, [getCategorias]); 
  useEffect(() => {
    changeFilter()
    console.log('categorias' , categorias)
  }, [selectedUser,startDate, endDate, selectedCategory]); 
  useEffect(() => {
    if(categorias&&categorias.length&&categoriasToShow.length==1){
      setCategoriasToShow([categoriasToShow[0], ...categorias])
    }
    console.log('categoriasToShow',categoriasToShow)
  }, [categorias]); 

  useEffect(() => {
    if(isLoadingProdutos){
      setLoading(true)
    }
    if(loading && !isLoadingProdutos){
      //getProdutos();
      setLoading(false)
    }
    console.log('isLoadingProdutos ==> ',isLoadingProdutos)
    
  }, [isLoadingProdutos]);
  useEffect(() => {
    if(isLoadingProdutosUpdate){
      setLoading(true)
    }
    if(loading && !isLoadingProdutosUpdate){
      getProdutos();
      setLoading(false)
    }
    console.log('isLoadingProdutosUpdate ==> ',isLoadingProdutosUpdate)
    
  }, [isLoadingProdutosUpdate]);
  useEffect(() => {
    console.log('loading ====>>> ',loading)
    if(isLoadingProdutosDelete){
      setLoading(true)
    }
    if(loading && !isLoadingProdutosDelete){
      //getProdutos();
      changeCategoria( selectedCategoria)
      setLoading(false)
    }
    console.log('isLoadingProdutosDelete ==> ',isLoadingProdutosDelete)
    
  }, [isLoadingProdutosDelete]);

  
  const sendNotification = ()=>{
    const notData = {
      type : 'success',
      message : '',
      title : 'Greentag',
      timeOut : 3000,
      callback : ()=>{},
      priority : true,
    }
    Notifications(notData)
  } 

  const handleDeleteClick = (produtoId: any) => () => {
    setSelectedProduto(produtoId);
    setDeleteConfirmationOpen(true);
    console.log('produtoId',produtoId)
  };

  const handleDeleteConfirmation = () => {
    deleteProduto(selectedProduto);
    setDeleteConfirmationOpen(false);
  };

  const clearNewProduto =() => {
    setNewProdutoName('');
    setNewProdutoMinimo('');
    setNewProdutoTelefone('');
    setNewProdutoEmpresa('');
    setNewProdutoQuantidade('');
    setNewProdutoCategoria('');
  }

  const handleAddProduto = () => {
    
    const novaProduto = {
      nome: newProdutoName,
      empresa: newProdutoEmpresa || '635c074796a268680647cdff',
      descricao : newProdutoDescricao,
      quantidade: 0,
      minimo: 0,
      categoria: "string",
      venda: 0,
      compra: 0,
      identificacao: [
        {
          "tipo": "string",
          "codigo": "string"
        }
      ],
      ativo: true,
    };
  
    createProduto(novaProduto);
    setAddProdutoModalOpen(false);
    clearNewProduto()
  };
  

  const validateEmail = (email:any) => {
    return true
    // Implemente a lógica de validação do e-mail aqui
  };
  
  const validateCNPJ = (cnpj:any) => {
    // Remove caracteres não numéricos
    cnpj = cnpj.replace(/[^\d]+/g, '');
    
    // Verifica se possui 14 dígitos
    if (cnpj.length !== 14) {
      return false;
    }
    
    // Verifica se todos os dígitos são iguais (ex: 00000000000000)
    if (/^(\d)\1+$/.test(cnpj)) {
      return false;
    }
    
    // Validação do primeiro dígito verificador
    let sum = 0;
    let factor = 5;
    for (let i = 0; i < 12; i++) {
      sum += parseInt(cnpj[i]) * factor;
      factor = factor === 2 ? 9 : factor - 1;
    }
    
    let digit = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (parseInt(cnpj[12]) !== digit) {
      return false;
    }
    
    // Validação do segundo dígito verificador
    sum = 0;
    factor = 6;
    for (let i = 0; i < 13; i++) {
      sum += parseInt(cnpj[i]) * factor;
      factor = factor === 2 ? 9 : factor - 1;
    }
    
    digit = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (parseInt(cnpj[13]) !== digit) {
      return false;
    }
    
    return true;
  };
  
  
  const maskCNPJ = (cnpj:any) => {
    // Remove caracteres não numéricos
    cnpj = cnpj.replace(/[^\d]+/g, '');
    
    // Aplica a máscara
    cnpj = cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    
    return cnpj;
  };
  
  const validatePhone = (phone:any) => {
    // Remove caracteres não numéricos
    phone = phone.replace(/[^\d]+/g, '');
    
    // Verifica se possui 11 dígitos (considerando o DDD)
    if (phone.length !== 11) {
      return false;
    }
    
    // Verifica se todos os dígitos são iguais (ex: 00000000000)
    if (/^(\d)\1+$/.test(phone)) {
      return false;
    }
    
    return true;
  };
  
  const maskPhone = (phone:any) => {
    // Remove caracteres não numéricos
    phone = phone.replace(/[^\d]+/g, '');
    
    // Aplica a máscara
    phone = phone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    
    return phone;
  };
  const zero = (n:any)=>{
    return Number(n) < 10 ? '0'+n : n
  }

  const makeDate = (date:any)=>{
    const data = new Date(date);
    const dia = zero(data.getDate());
    const mes = zero(data.getMonth() + 1);
    const ano = data.getFullYear();
    const label = (`${dia}/${mes}/${ano}`);
    return label
  }

  const handleEditProduto = (produto:any) => {
    console.log('produto selecionada ==>> ', produto)
    setPasswordOpen(false);
    setNewProdutoQuantidade('')
    setNewProdutoCategoria('')
    setEditProdutoData(produto);
    setEditProdutoModalOpen(true);
  };
  const handleLogsProduto = (prod:any) => {
    const id = prod._id
    console.log('id selecionada ==>> ', id)
    setPasswordOpen(false);
    setNewProdutoQuantidade('')
    setNewProdutoCategoria('')
    setSelectedProduto(id);
    setProdutoId(id);
    setProduto(prod);
    setEditProdutoModalOpen(true);
    navigate('/logs')
  };
  
  const handleUpdateProduto = () => {
    
    console.log('editProdutoData',editProdutoData)
    const dataToSend = editProdutoData
    if(dataToSend.Produto){
        dataToSend.referencia = dataToSend.Produto
        delete dataToSend.Produto
    }
    if(anotation){
      dataToSend.dadosAdicionais = {text : anotation}
    }
   
    if(file){
      dataToSend.file = file;
      dataToSend.contador = (contador+1);
    } 
    console.log('dataToSend',dataToSend)
    updateProduto(dataToSend)
    setEditProdutoData({});
    setEditProdutoModalOpen(false);
    // Lógica para atualizar a Produto com os dados do modal
  };

  const validatePassword = (password: string | any[]) => {
    return password.length >= 6;
  };
  
  const validateCategoria = (password: any, confirmPassword: any) => {
    return password === confirmPassword;
  };

  const changeCategoria = (cat:any)=>{
    
    console.log('categoria id: ',cat)
    
    if (!cat){
      getProdutos()
    }else{
      getProdutosByCategory(cat)
    }
    
  }

 
  

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    const el = rows.filter((item:any)=>item._id == id)
    console.log('handleSaveClick', id)
    console.log('handleSaveClick', el)
    setSave(true)
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
  }
  
  function EditToolbar(props: EditToolbarProps) {
    const { setRows, setRowModesModel } = props;
  
    const handleClick = () => {
      const id = randomId();
      setRows((oldRows) => [...oldRows, { _id : id , nome: '', quantidade: '', identificacao:[{codigo:'', tipo:''}], isNew: true }]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'nome' },
      }));
    };
  
    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Add record
        </Button>
      </GridToolbarContainer>
    );
  }

  const _ = (id: GridRowId) => () => {
    setRows(rows.filter((row:any) => row._id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row:any) => row._id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row:any) => row._id !== id));
    }
  };

  const processRowUpdate:any = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    console.log('newRow',newRow)
    setEditProdutoData(newRow)
    setRows(rows.map((row:any) => (row._id === newRow._id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const getLogs = (id: any) => () => {
    const produto = rows.filter((item:any)=> id == item._id)
    handleLogsProduto(produto[0])
  }

  function isOverflown(element: Element): boolean {
    return (
      element.scrollHeight > element.clientHeight ||
      element.scrollWidth > element.clientWidth
    );
  }

  const ImageComponent = (obj: any)=>{
    //console.log(obj)
    //setEditReferenciaData(obj.obj.row)
    if(obj.obj?.value){
      return (
        <div style={{alignSelf:'center'}} onClick={()=>{
          //console.log(obj.obj.value)
          setImageToOpen(obj.obj.value)
          console.log(imageToOpen)
          handleClickImage()
        }}>
          <img style={{objectFit:'cover'}} height={60} width={60} src={obj.obj.value} />
        </div>
        
      )
    }else{
      return(
        <IconButton onClick={
          ()=>{
            //handleEditReferencia(obj.obj.row)
            }
          }>
          <ImageSearch />
        </IconButton>
      )
    }
    
  }

  const columns: GridColDef[] = [
    { field: '_id', headerName: 'ID', width: 90 },
    {
      field: 'imagem',
      headerName: 'Imagem',
      width: 80,
      editable: false,
      renderCell: (params) => <ImageComponent obj={params} />, // renderCell will render the component
    },
    {
      field: 'createdAt',
      headerName: 'Data',
      type:'Date',
      width: 110,
      editable: false,
      valueGetter : (params: GridValueGetterParams)=> makeDate(params.row.createdAt)
    },
    {
      field: "referencia",
      headerName: 'Produto',
      width: 160,
      editable: true,
      type: "singleSelect",
      valueOptions: referencias,
      //valueSetter:  (value: any) => value?._id ,
      getOptionValue: (value: any) => value._id,
      getOptionLabel: (value: any) => value.nome,
      valueGetter : (params: GridValueGetterParams)=> params.row.referencia?._id || ''
    },
    {
      field: 'nome',
      headerName: 'Nome',
      width: 140,
      editable: true,
    },
    
    {
      field: 'quantidade',
      headerName: 'Quantidade',
      type: 'number',
      width: 80,
      editable: false,
    },
    {
      field: 'identificacao',
      headerName: 'Código',
      description: 'código do item',
      sortable: false,
      width: 140,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.identificacao[0].codigo || ''}  `,
    }, 
    {
      field: 'categoria',
      headerName: 'Unidade',
      description: 'unidade do item',
      sortable: false,
      width: 140,
      valueGetter: (params: GridValueGetterParams) =>
        `${getCategoriaNome(params.row.categoria) || ''}  `,
    }, 
    {
      field: 'updatedAt',
      headerName: 'Tipo identificacao',
      description: 'tipo de identificacao',
      sortable: false,
      width: 75,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.identificacao[0].tipo || ''} `,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Ações',
      width: 200,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
  
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
  
        return [
          <GridActionsCellItem
            icon={<Tooltip TransitionComponent={Zoom}   title="Editar" arrow>
            <IconButton  size='small' className='iconDetail' color="primary" aria-label="Editar" >
              <EditIcon />
            </IconButton>
          </Tooltip>}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<Tooltip TransitionComponent={Zoom}   title="Logs" arrow>
            <IconButton size='small' className='iconDetail' color="primary" aria-label="Editar" >
              <ViewHeadline />
            </IconButton>
          </Tooltip>}
            label="Ver logs"
            className="textPrimary"
            onClick={getLogs(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<Tooltip TransitionComponent={Zoom}   title="QrCode" arrow>
            <IconButton size='small' className='iconDetail' color="primary" aria-label="Editar" >
              <QrCode />
            </IconButton>
          </Tooltip>}
            label="Código de barras"
            className="textPrimary"
            onClick={()=>{
              const codigo = (rows.filter((item:any)=> id == item._id))[0].identificacao[0].codigo
              let tipo = (rows.filter((item:any)=> id == item._id))[0].identificacao[0].tipo.replace('_','')
              if(tipo == 'RFID'){
                tipo = 'CODE39'
              }
              console.log(tipo)
              setBarcode(codigo)
              setBarcodeTipo(tipo)
            }}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<Tooltip TransitionComponent={Zoom}   title="Apagar" arrow>
            <IconButton size='small' className='iconDetail' color="primary" aria-label="Editar" >
              <DeleteIcon />
            </IconButton>
          </Tooltip>}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
    
    
  ];

  const downloadQR = () => {
    const canvas:any = document.getElementById("barcode");
    console.log('canvas ==> ',canvas);
    const svg = canvas.querySelector("svg");
    console.log('svg',svg);
    
    const serializer = new XMLSerializer();
    const svgStr = serializer.serializeToString(svg);
    console.log('svgStr',svgStr);
   
    const img:any = document.querySelector('#image');
    console.log('img ==> ',img);
    const target:any = 'data:image/svg+xml;base64,'+ window.btoa(svgStr);
    img.src = target
    
    svg.parentNode.removeChild(svg);
    console.log('canvas',canvas)
    /* const pngUrl = target
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
      return; */
    let downloadLink = document.createElement("a");
    downloadLink.href = target;
    downloadLink.download = "123456.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };


  const getCategoriaNome = (id:any)=>{
    const nome = categories.filter((item:any)=>id == item._id)
    return nome.length ? nome[0].nome : id

  }

  const changeFilter = ()=>{

    const objToSend = {
      startDate,
      endDate,
      empresa: usuario.empresa,
      categoria:selectedCategory?._id || '',
      usuario : selectedUser?._id || '',
      
    }
    console.log('objToSend', objToSend)
    //getProductsByFilter(objToSend)
    getProdutosByFilter(objToSend)
  
  }

  const handleClickImage = ()=>{
    setOpen((previousOpen) => !previousOpen);
  }
  
  
  return (
    <Container className={classes.container}>
      <Modal  onClick={(evt)=>{
                      setImageToOpen('')
                      handleClickImage()
                    }} open={open} style={{width:'100%', textAlign: 'center', display: 'flex',
            alignItems : 'center', justifyContent: 'space-between',backgroundColor:'white'}}>
            <Box>
              <Typography>
                clique para fechar
              </Typography>
              <Typography>
                {editProdutoData.referencia?.nome}
              </Typography>
               <img width={500} style={{border:'2px solid white', maxHeight:'80vh'}}
                    src={`${imageToOpen}?w=248&fit=crop&auto=format`}
                    srcSet={`${imageToOpen}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={''}
                    loading="lazy"
                   
                  />
            </Box>
           

          </Modal>
      
      <div className={classes.root}>
          <div className={classes.content}>
          {loading ? (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      ) : null}
          <Button className='backbutton' onClick={() => navigate(-1)}>Voltar</Button>
          
          <Typography variant="h4" className={'title'}>
            Gerenciar Itens
          </Typography>
          {/* <Button className={classes.addButton} variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => setAddProdutoModalOpen(true)}>
            Adicionar Item
          </Button> */}

          { barcode ? (
            <Modal open={barcode} onClose={() => setBarcode(0)}>
              <div style={{width:'100%', backgroundColor:'white', marginTop:50, textAlign: 'center', display: 'flex',alignItems : 'center', justifyContent: 'space-between',flexWrap: 'wrap', flexDirection: 'column'}} onClick={()=>{}}>
                <div>
                  <Typography variant="h4" className={'title'}>
                    Código de barras
                  </Typography>
                  <div id='barcode'>
                    <Barcode  format={barcodeTipo} value={barcode} />
                  </div>
                  <img id="image"/>
                  <a onClick={downloadQR}> Download QR </a>
                </div>
              </div>
            </Modal>
              
              ) : null
            }
          {produtos.length?(
            <div className={'text'} style={{margin:15}}>
              {produtosList.length} Itens encontrados
            </div>
          ):null}


<Box sx={{  width: '100%', height:180, }} style={{ alignSelf:'center', paddingTop:10, paddingBottom:10, borderColor:'green', borderWidth:2}}>
            <Stack   borderRadius={15} spacing={2} direction="column" alignItems={'center'} alignContent={'center'} divider={<Divider orientation="vertical" flexItem />}>
            <Box sx={{  width: '100%' }} style={{display: 'flex',flexDirection: 'row',flexWrap: 'nowrap',height:30, alignContent: 'center', justifyContent: 'space-around', alignSelf:'center', paddingTop:10, paddingBottom:10, borderColor:'green', borderWidth:2}}>
              <Autocomplete
                getOptionLabel={(cat:any) => getCategoriaNome(cat.nome) }
                //getOptionSelected={(option:any, value:any) => option.nome === value.nome }
                onChange={(event, newValue) => {
                    console.log(newValue)
                    setSelectedCategory(newValue)
                    //if(!newValue){
                      
                      //getAllInventoryStart()
                      //return
                    ///}
                    changeCategoria(newValue?._id)
                    // changeFilter()
                    //getInventoriesByCategory(newValue._id)
                }}
                disablePortal
                id="combo-box-demo"
                options={categories}
                sx={{ width: 300 }}
                renderInput={(params:any) => <TextField {...params} label="Unidades" />}
              />
            <Autocomplete
                getOptionLabel={(usu:any) => usu.nome }
                //getOptionSelected={(option:any, value:any) => option.nome === value.nome }
                onChange={(event, newValue) => {
                    console.log(newValue)
                    setSelectedUser(newValue)        
                    // changeFilter()
                    
                }}
                disablePortal
                id="combo-box-usuario"
                options={usuarios}
                sx={{ width: 300 }}
                renderInput={(params:any) => <TextField {...params} label="Operador" />}
              />
            
            </Box>
            <Box sx={{  width: '100%' }} style={{display: 'flex',flexDirection: 'row',flexWrap: 'nowrap',height:70, alignContent: 'center', justifyContent: 'center', alignSelf:'center', paddingTop:10, paddingBottom:10, borderColor:'green', borderWidth:2}}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker', 'DatePicker']}>
                  <DatePicker label="Inicial" 
                          //views={["day", "month", "year"]}
                          //view={["day", "month", "year"]}
                          /* slots={{ toolbar: CustomToolbar }}
                          slotProps={{ toolbar: {} }} */
                          /* slotProps={{
                            actionBar: {
                              actions: ['clear'],
                            },
                          }} */
                          format="DD/MM/YYYY"
                          onChange={(value:any)=>{
                            if(!value) return
                            console.log('inicial',value.$d)
                            setStartDate(value.$d)
                          }} 
                        />
                  <DatePicker label="Final" 
                          format="DD/MM/YYYY"
                          onChange={(value:any)=>{
                            if(!value) return
                            const valueDate = new Date(value.$d)
                            valueDate.setHours(23)
                            valueDate.setMinutes(59)
                            valueDate.setSeconds(59)
                            //console.log('day', day)
                            console.log('final', valueDate)
                            setEndDate(valueDate)
                          }}
                        
                        />
                </DemoContainer>
              </LocalizationProvider>
              <Button className='{classes.irButton}' onClick={() => changeFilter()}>Ir</Button>
            </Box>
            
              

              

              
             
          </Stack>
          
          
          </Box> 

         {/*  <FormControl fullWidth>
              <InputLabel className={'text'} htmlFor="tipo-select">Itens por Unidade</InputLabel>
              <Select
                className={classes.select}
                value={selectedCategoria}
                onChange={(e:any) => {

                  setSelectedCategoria( e.target.value )
                  changeCategoria( e.target.value)
                  
                }}
                inputProps={{
                  name: 'categoria',
                  id: 'tipo-select',
                }}
              >
                {categoriasToShow.map((cat: { _id: any; nome: any; }) => (
                  <MenuItem className={'text'} key={cat._id} value={cat._id}>
                    {cat.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
            
            
          {produtosList.length ? (
            <Box sx={{  width: '100%' }}>
              
            <DataGrid
              apiRef={apiRef}
              loading={isLoadingProdutos}
              getRowId={(row: { _id: any; }) => row._id}
              
              autoHeight={true}
              rows={rows}
              columns={columns}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'createdAt', sort: 'desc' }],
                },
                pagination: {
                  paginationModel: {
                    pageSize: 15,
                  },
                },
              }}
              pageSizeOptions={[5]}
              checkboxSelection
              disableRowSelectionOnClick
              editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              // slots={{
              //   toolbar: EditToolbar,
              // }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: { setRows, setRowModesModel,csvOptions:{
                  fileName: 'itensDataBase_',
                  delimiter: ';',
                  utf8WithBom: true,
                },
                columns: {
                  columnVisibilityModel:{ID: false,},
                },
                columnsPanel: {
                  getTogglableColumns,
                },
                printOptions: {hideToolbar:true, disableToolbarButton: false }},
              }}

              
       
              //toolbar: { printOptions: { getRowsToExport: getSelectedRowsToExport } },
        
        
            />
              
          </Box>
          ):(<div>
            Nehum Item encontrado
          </div>)}
          {/* {produtosList.length ? (
            <div className={'listContainer'}>
              
              <List >
                {produtosList.map((produto: any, index: any) => (
                  <ListItem key={index} className={classes.listItem} divider>
                      <div onClick={()=>setModalProdutoData(produto)}>
                          <Typography className={'text'}>{produto.nome}</Typography>
                          <Typography className={'text'}>{produto.identificacao[0].codigo}</Typography>
                      </div>
                    
                    <div>
                      <Tooltip TransitionComponent={Zoom}   title="Editar" arrow>
                        <IconButton className='iconEdit' color="primary" aria-label="Editar" onClick={() => handleEditProduto(produto)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip TransitionComponent={Zoom}   title="Movimentações" arrow>
                        <IconButton className='iconDetail' color="primary" aria-label="Editar" onClick={() => handleLogsProduto(produto)}>
                          <ViewHeadline />
                        </IconButton>
                      </Tooltip>
                      <Tooltip TransitionComponent={Zoom}   title="Apagar" arrow>
                        <IconButton
                          color="secondary"
                          aria-label="Deletar"
                          className="iconDelete"
                          onClick={() => _(produto._id)}
                        >
                          
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                      
                      
                    </div>
                  </ListItem>
                  
                ))}
              </List>

            </div>
            
          ) : <div className={classes.information}>
                Nenhum Item encontrado
              </div>} */}
          <Button className='backbutton' onClick={() => navigate(-1)}>Voltar</Button>
        </div>
      </div>

      <Dialog open={deleteConfirmationOpen} onClose={() => setDeleteConfirmationOpen(false)}>
        <DialogTitle>Confirmar exclusão</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Tem certeza de que deseja excluir este item?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmationOpen(false)}>Cancelar</Button>
          <Button onClick={handleDeleteConfirmation} color="secondary">
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
      
      <Dialog open={addProdutoModalOpen} onClose={() => setAddProdutoModalOpen(false)}>
        <DialogTitle>Adicionar Item</DialogTitle>
        <DialogContent className={classes.modalContent}>
          <TextField
            label="Nome da Produto"
            value={newProdutoName}
            onChange={(e) => setNewProdutoName(e.target.value)}
          />
          {/* <TextField
            label="E-mail"
            value={newProdutoMinimo}
            onChange={(e) => setNewProdutoMinimo(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              ),
            }}
            error={!validateEmail(newProdutoMinimo)}
            helperText={!validateEmail(newProdutoMinimo) && 'E-mail inválido'}
          /> */}
          
          <FormControl fullWidth>
              <InputLabel htmlFor="empresa-select">Selecione a Empresa</InputLabel>
              <Select
                value={newProdutoEmpresa}
                onChange={(e:any) => setNewProdutoEmpresa( e.target.value )}
                inputProps={{
                  name: 'empresa',
                  id: 'empresa-select',
                }}
              >
                {empresas.map((empresa: { _id: any; nome: any; }) => (
                  <MenuItem key={empresa._id} value={empresa._id}>
                    {empresa.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

          
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddProdutoModalOpen(false)}>Cancelar</Button>
          <Button onClick={handleAddProduto} color="primary">
            Criar
          </Button>
        </DialogActions>
      </Dialog>
      { editProdutoModalOpen && editProdutoData.nome ?
      <Dialog maxWidth="lg" open={editProdutoModalOpen} onClose={() => setEditProdutoModalOpen(false)}>
        <DialogTitle>Editar Produto</DialogTitle>
        <DialogContent className={classes.modalContent}>
          <TextField
            label="Nome da Produto"
            value={editProdutoData.nome}
            onChange={(e) => setEditProdutoData({ ...editProdutoData, nome: e.target.value })}
          />
          
            <TextField
              label="Empresa"
              value={editProdutoData.empresa}
              onChange={(e) => setNewProdutoEmpresa(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Business />
                  </InputAdornment>
                ),
              }}
              error={editProdutoData.empresa == ""}

              helperText={
                editProdutoData.empresa=="" &&
                'Empresa inválida'
              }
            />


            <div className="file-upload">
              {/* <img src={uploadImg} alt="upload" /> */}
              <CloudUpload />
              <h3> {file?.name || "Clique para escolher uma imagem"}</h3>
              <p>Tamanho máximo do arquivo 10mb</p>
              <input type="file" onChange={handleFileChange} />
            </div>

            
              
            

            <div>{file && `${file.name} - ${file.type}`}</div>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditProdutoModalOpen(false)}>Cancelar</Button>
          <Button onClick={handleUpdateProduto} color="primary">
            Atualizar
          </Button>
        </DialogActions>
      </Dialog>:null}

      
{modalProdutoData.nome ? (
    <Modal open={modalProdutoData.nome} onClose={() => setModalProdutoData({})}>
  <div>
 


    <input
      type="text"
      value={modalProdutoData.nome}
      onChange={(e) => setEditProdutoData({ ...modalProdutoData, nome: e.target.value })}
    />
    <input
      type="text"
      value={modalProdutoData.identificacao[0].codigo}
      onChange={(e) => setEditProdutoData({ ...modalProdutoData, nome: e.target.value })}
    />
    <input
      type="text"
      value={modalProdutoData.quantidade}
      onChange={(e) => setEditProdutoData({ ...modalProdutoData, nome: e.target.value })}
    />
    <input
      type="text"
      value={(categorias.find((item:any)=> item._id == modalProdutoData.categoria))?.nome||''}
      onChange={(e) => setEditProdutoData({ ...modalProdutoData, email: e.target.value })}
    />
    <input
      type="text"
      value={(empresas.find((item:any)=> item._id == modalProdutoData.empresa))?.nome||''}
      onChange={(e) => setEditProdutoData({ ...modalProdutoData, telefone: e.target.value })}
    />
    <input
      type="text"
      value={modalProdutoData.compra}
      onChange={(e) => setEditProdutoData({ ...modalProdutoData, telefone: e.target.value })}
    />
    <input
      type="text"
      value={modalProdutoData.venda}
      onChange={(e) => setEditProdutoData({ ...modalProdutoData, telefone: e.target.value })}
    />
    <input
      type="text"
      value={modalProdutoData.minimo}
      onChange={(e) => setEditProdutoData({ ...modalProdutoData, cnpj: e.target.value })}
    />
    <button onClick={handleUpdateProduto}>Atualizar</button>
  </div>
</Modal>
)
  : null}
    
    </Container>
  );
};

const mapStateToProps = (state: any) => ({
  referencias: state.referencias.referencias , 
  produtos: state.produtos.produtos , 
  imagesByProduct: state.produtos.imagesByProduct , 
  imagesByReferencia: state.referencias.imagesByReferencia , 
  isLoadingProdutos : state.produtos.isLoadingProdutos,
  isLoadingProdutosDelete : state.produtos.isLoadingProdutosDelete,
  isLoadingProdutosUpdate : state.produtos.isLoadingProdutosUpdate,
  empresas: state.empresas.empresas,
  categorias: state.categorias.categorias,
  categories : state.categorias.categorias,
  produtoId : state.produtos.produtoId,
  usuarios: state.usuarios.usuarios, 
  usuario : state.user.user,
});

const mapDispatchToProps = {
    setProdutoId,
    setProduto,
    getCategorias,
    fetchEmpresas,
    getProdutos,
    getProdutosByCategory,
    getProdutosByFilter,
    deleteProduto,
    createProduto,
    updateProduto,
    getImagesByProduct,
    getReferencias,
    getImagesByReferencia,
    getSelectedImage
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ProdutosPage);
