import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Container, Typography, List, ListItem, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Modal, PopperPlacementType, Paper } from '@material-ui/core';
//import Fade from '@mui/material/Fade';
import Zoom from '@mui/material/Zoom';
import Tooltip from '@mui/material/Tooltip';
import GoogleMapReact from 'google-map-react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { useLoadScript } from '@react-google-maps/api';
import PinDrop from '@material-ui/icons/PinDrop';
import { DatePicker, DateCalendar } from '@mui/x-date-pickers';
import { FormControl, InputLabel, Select, MenuItem, InputAdornment} from '@material-ui/core';
import Pagination from '@mui/material/Pagination';
import { connect, ConnectedProps } from 'react-redux';
import store from './../redux/store';
import { getLogs, setProdutoId, setLogSelecionado, getImagesByProduct, getSelectedImage, getLogsByFilter} from '../redux/produtos/actions';
import { fetchEmpresas } from './../redux/empresas/actions'
import { getUsuarios } from './../redux/usuarios/actions'
import { useNavigate } from 'react-router-dom';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import MapIcon from '@material-ui/icons/Map';
import { green } from '@material-ui/core/colors';
import {VictoryBar, VictoryLabel} from 'victory'
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import { Email, Phone, Business, ViewHeadline } from '@material-ui/icons';

import Badge from '@mui/material/Badge';

import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';

import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Fab from '@mui/material/Fab';

import UpIcon from '@mui/icons-material/KeyboardArrowUp';
import LeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import RightIcon from '@mui/icons-material/KeyboardArrowRight';

import { SxProps } from '@mui/system';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';

import {serverConfig} from './../api/apiConfig';

import Popper from '@mui/material/Popper';
import { useSpring, animated } from '@react-spring/web';

import {
  useGridApiRef,
  
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbar,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridValueGetterParams,
  GridToolbarExport,
} from '@mui/x-data-grid';
import moment from 'moment';
import { Autocomplete, Divider, ListItemButton, ListSubheader, Stack } from '@mui/material';

import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';


import { DefaultizedPieValueType } from '@mui/x-charts';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    textAlign: 'center',
    
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    //alignItems: 'center',
    height: '100%',

    
  },
  content: {
    textAlign: 'center',
  },
  listContainer: {
    maxHeight: 'calc(100vh - 200px)',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'green',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: 'darkgreen',
    },
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  calendar:{
    color:'#008800!important',
  },
  subtitle: {
    fontSize:18,
    fontWeight:'bold',
    margin: theme.spacing(2),
    
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
  },
  addButton: {
    margin: theme.spacing(1),
    backgroundColor: green[500],
    color: 'white',
    '&:hover': {
      backgroundColor: green[700],
    },
    fontSize:14,
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  customHoverFocus: {
    "&:hover, &.Mui-focusVisible": { backgroundColor: "white" }
  },
  buttonOver: {
    color: 'white',
    "&:hover": {
        "& .MuiSvgIcon-root":{
            color: theme.palette.primary.light,
        }
    },
},
buttonIcon: {
    color: theme.palette.common.white,
},
text:{
  color:'#efdba0',
  fontSize: 14,
},
icon:{
  color:'#efdba0',
  
},
address:{
  color:'#efdba0',
  fontSize: 13
},
balloon:{
  width:150,
  height:100,
  borderRadius:15,
  borderWidth:2,
  borderColor:'black',
  backgroundColor:'green',
  color:'white',
  padding:'10px',
}
}));

interface FadeProps {
  children?: React.ReactElement;
  in?: boolean;
  onEnter?: () => void;
  onExited?: () => void;
}


const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});


interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      //style={{backgroundColor:'#008000'}}
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index: any) {
  return {
    id: `action-tab-${index}`,
    'aria-controls': `action-tabpanel-${index}`,
  };
}

const fabStyle = {
  position: 'absolute',
  bottom: 16,
  right: 16,
};

const fabGreenStyle = {
  color: 'common.white',
  bgcolor: green[500],
  '&:hover': {
    bgcolor: green[600],
  },
};



function getRandomNumber(min: number, max: number) {
  return Math.round(Math.random() * (max - min) + min);
}

/**
 * Mimic fetch with abort controller https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort
 * ⚠️ No IE11 support
 */




function ServerDay(props: PickersDayProps<Dayjs> & { highlightedDays?: number[] }) {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const isSelected =
    !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;

  return (
    <Badge
      key={props.day.toString()}
      overlap="circular"
      //☝️📍📝✒️🔍🔒🔓⭕🌚
      badgeContent={isSelected ? '✒️' : undefined}
    >
      <PickersDay style={{color:'white'}} {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
    </Badge>
  );
}



const googleKey ="AIzaSyD6_lxrnSfNGuhKxErqagxzGb5AGuCcZbg";
type PropsFromRedux = ConnectedProps<typeof connector>;

const LogsPage: React.FC<PropsFromRedux> = ({logs, getLogs,produtoId,usuarios,usuario,getUsuarios, produto, getLogsByFilter,setProdutoId, setLogSelecionado,selectedImage,imagesByProduct, getImagesByProduct, getSelectedImage, isLoadingLog, empresas, fetchEmpresas,categorias}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false);
  const [selectedlog, setSelectedlog] = React.useState('');
  const [addlogModalOpen, setAddlogModalOpen] = React.useState(false);
  const [newlogCodigo, setNewlogCodigo] = React.useState('');
  const [newlogEmpresa, setNewlogEmpresa] = React.useState('');
  const [newlogQuantidade, setNewlogQuantidade] = React.useState('');
  const [newlogTipo, setNewlogTipo] = React.useState('');
  const [newlogLocalizacaoInterna, setNewlogLocalizacaoInterna] = React.useState('');
  const [newlogDadosAdicionais, setNewlogDadosAdicionais] = React.useState('');
  const [newlogIdentificacao, setNewlogIdentificacao] = React.useState('');
  const [lat, setLat] = React.useState(0);
  const [lng, setLng] = React.useState(0);
  const [zip, setZip] = React.useState('');
  const [editlogData, setEditlogData] = React.useState<any>({});
  const [editlogModalOpen, setEditlogModalOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [position, setPosition] = React.useState({});
  const [logsData, setLogsData] = React.useState<any>([]);
  const [logsCoords, setLogsCoords] = React.useState<any>([]);
  const requestAbortController = React.useRef<AbortController | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [initialValue, setInitialValue] = React.useState(dayjs(new Date()));
  const [highlightedDays, setHighlightedDays] = React.useState([1, 2, 15]);
  const [defaultProps, setDefaultProps] = React.useState<any>({});
  const [selectedPin, setSelectedPin] = React.useState<any>({});
  const [long, setLong] = React.useState<any>('');
  const [slides,setSlides] = React.useState<any>([]);
  const [open, setOpen] = React.useState(false);
  const [imageToOpen, setImageToOpen] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [placement, setPlacement] = React.useState<PopperPlacementType>();
  const [rows, setRows] = React.useState(logs);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

  const [selectedUser, setSelectedUser] = React.useState<any>({key:0,value:'Filtro por operador'});
  const [selectedCategory, setSelectedCategory] = React.useState<any>({key:0,value:'Filtro por unidade'});
  const [startDate, setStartDate] = React.useState<any>(null);
  const [endDate, setEndDate] = React.useState<any>(null);
  
  const apiRef = useGridApiRef();
  /*  {x: 1, y: 3, label: "Alpha"},
    {x: 2, y: 4, label: "Bravo"}, */

  const tipos = [
                  {_id : 1, nome : 'Entrada'},
                  {_id : 2, nome : 'Saida'},
                  {_id : 3, nome : 'Anotação'},
                  //{_id : 4, nome : 'Entrada'},
                ]

  const objDataMov = {
    "codigo": {
      "tipo": "CODE_128",
      "codigo": "string"
    },
    "tipo": "Entrada",
    "quantidade": 0,
    "empresa": "string",
    "localizacao": {},
    "dadosAdicionais": {},
    "localizacaoInterna": {}
  }
  const objDataProduto = {
    "nome": "string",
    "empresa": "string",
    "quantidade": 0,
    "minimo": 0,
    "categoria": "string",
    "venda": 0,
    "compra": 0,
    "identificacao": [
      {
        "tipo": "string",
        "codigo": "string"
      }
    ],
    "ativo": true,
    "descricao": "string"
  }

  const handleClick = () => {
    //setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  

  /* const handleClick =
  (newPlacement: PopperPlacementType) =>
  (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    //setOpen((prev) => placement !== newPlacement || !prev);
    setOpen((previousOpen) => !previousOpen);
    setPlacement(newPlacement);
    console.log('newPlacement',newPlacement)
  }; */

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? 'spring-popper' : undefined;

  function fakeFetch(date: Dayjs, { signal }: { signal: AbortSignal }) {
    return new Promise<{ daysToHighlight: number[] }>((resolve, reject) => {
      const timeout = setTimeout(() => {
        console.log('date ==> ', makeDate(date))
        const dateArr = makeDate(date).split('/')
        const mes = dateArr[1];
        const ano = dateArr[2]
        //console.log('date ==> ', date)
        const daysInMonth = date.daysInMonth();
        //const daysToHighlight = [1, 2, 3].map(() => getRandomNumber(1, daysInMonth));
        const daysFilter = logs.filter((item:any, i:any) => {
          const dt = makeDate(item.createdAt).split('/')
          console.log(dt)
          const newMes = dt[1];
          const newAno = dt[2]
          const dia = dt[0]
          if(ano == newAno && mes == newMes){
            return(dia)
          }else{
            return false
          }

        });
        const daysToHighlight = daysFilter.map((item:any,index:any)=> Number(makeDate(item.createdAt).split('/')[0]) )
        console.log('daysToHighlight', daysToHighlight)
        resolve({ daysToHighlight });
      }, 500);
  
      signal.onabort = () => {
        clearTimeout(timeout);
        reject(new DOMException('aborted', 'AbortError'));
      };
    });
  }

  

  const handleChange = (event: unknown, newValue: number) => {
    
    setValue(newValue);
    console.log(newValue)
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const fabs = [
    {
      color: 'primary' as 'primary',
      sx: fabStyle as SxProps,
      icon: <AddIcon />,
      label: 'Add',
    },
    {
      color: 'secondary' as 'secondary',
      sx: fabStyle as SxProps,
      icon: <EditIcon />,
      label: 'Edit',
    },
    {
      color: 'inherit' as 'inherit',
      sx: { ...fabStyle, ...fabGreenStyle } as SxProps,
      icon: <UpIcon />,
      label: 'Expand',
    },
  ];

  useEffect(() => {
    //setProdutosList(produtos)
    setRows(logs)
    console.log("setProdutosList ====> ", logs)
    if(logs?.length && value == 0){
      apiRef.current.setColumnVisibility('_id', false);
    }
  }, [logs]);
    
  
 
  useEffect(()=>{
    // Create the new date
    
    setLogsData([])
    setLogsCoords([])
    fetchHighlightedDays(initialValue);
    // abort request on unmount
    return () => requestAbortController.current?.abort();
  },[])

  const zero = (n:any)=>{
    return Number(n) < 10 ? '0'+n : n
  }
  const makeDate = (date:any)=>{
    const data = new Date(date);
    const dia = zero(data.getDate());
    const mes = zero(data.getMonth() + 1);
    const ano = data.getFullYear();
    const label = (`${dia}/${mes}/${ano}`);
    return label
  }
  useEffect( ()=>{
    if(!usuario.length){
      //getUsuarios()
    }
  },[usuarios])

  useEffect(() => {
    changeFilter()
    
  }, [selectedUser,startDate, endDate]); 


  useEffect( ()=>{
    console.log('logs', logs)
    var newData: { x: number; y: any; label: string; }[] =[];
    var newDataCoords: any[] =[];
    
    const getAllAddress = async ()=>{
      for(var i = 0 ; i < logs.length ; i++){
        let item = logs[i]
        const address = await getAddress(item.localizacao.coords.latitude , item.localizacao.coords.longitude)
        newDataCoords.push(address)
      }
      setLogsCoords(newDataCoords)
      console.log('newDataCoords',newDataCoords)
    }
    if(logs&&logs.length){
      for(var i = 0 ; i < logs.length ; i++){
        let item = logs[i]
        console.log(item)
        const label = makeDate(item.createdAt)
        
        let obj = {x: i+1, y: item.quantidade, label}
        newData.push(obj)
        
        
      }
      console.log('newData ==> ',newData)
      
      setLogsData([...newData,...logsData])
      
      
      getAllAddress().catch(console.error);
    }
    
    
  },[logs])

  useEffect(() => {
    console.log("imagesbyproduct", imagesByProduct)
    const imagesList:any = [];
    imagesByProduct.map((item:any, index:any)=>{
      const slide = {
        key: index,
        title: 'Greentag',
        text: '',
        image: serverConfig.dev.url + 'imagens/imagem/' + item._id,
        backgroundColor: 'green',
        
      }
      imagesList.push(slide)
    })
     setSlides(imagesList)
   
  },[imagesByProduct]);


  useEffect(() => {
    setAddlogModalOpen(false)
    getLogs(produtoId);
    getPosition();
    getImagesByProduct(produtoId)
  }, [produtoId]);
  useEffect(() => {
    
    fetchEmpresas();
  }, [fetchEmpresas]);

  useEffect(() => {
    /* if(isLoadingLog){
      setLoading(true)
    }
    if(loading && !isLoadingLog){
      
      getLogs(produtoId);
      setLoading(false)
    } */
    console.log('isLoadingLog ==> ',isLoadingLog)
    
  }, [isLoadingLog]);

  const handleDeleteClick = (empresaId: string) => {
    setSelectedlog(empresaId);
    setDeleteConfirmationOpen(true);
  };

   // If browser supports navigator.geolocation, generate Lat/Long else let user know there is an error
const getPosition = () => {
    if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(showPosition, posError); // Passing in a success callback and an error callback fn
    } else {
    alert("Sorry, Geolocation is not supported by this browser."); // Alert is browser does not support geolocation
    }
}
// Geolocation error callback fn. Query permissions to check if the error occured due to user not allowing location to be shared
const posError = () => {
    if (navigator.permissions) {
    navigator.permissions.query({ name: 'geolocation' }).then(res => {
    if (res.state === 'denied') {
    alert('Enable location permissions for this website in your browser settings.')
    }
    })
    } else {
    alert('Unable to access your location. You can continue by submitting location manually.') // Obtaining Lat/long from address necessary
    }
}
    // Geolocation success callback fn
const showPosition = (position:any) => {
    setPosition(position)
    let lat = position.coords.latitude // You have obtained latitude coordinate!
    let long = position.coords.longitude // You have obtained longitude coordinate!
    setLat(lat) // Using dispatch to modify lat store state
    setLng(long) // Using dispatch to modify long store state
    //getAddress(lat, long) // Will convert lat/long to City, State, & Zip code
}
    // Fetching for google API key from back-end (Optional, you can store it in .env file in front-end)
/* const convertToAddress = (lat:any, long:any) => {
    fetch('http://localhost:3000/googlemaps')
    .then(res => res.json())
    .then(obj => getAddress(lat, long))
} */
// Converting lat/long from browser geolocation into city, state, and zip code using Google Geocoding API
const getAddress = async (lat:any, long:any, index?:any) => {
    console.log(lat, long)
    const res = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${googleKey}`)
    console.log('res ====> ', res)
    const address:any = await res.json()
    console.log('address ====> ', address)
    const final = address.results[0].formatted_address
    console.log(final)
    
    return final
    
    /* fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${googleKey}`)
    .then(res => res.json())
    .then(address => {
      console.log('zip ==> ',address)
      //setZip(address.results[0].formatted_address)
      return address.results[0].formatted_address
    }) */
}

  const handleDeleteConfirmation = () => {
    //deletelog(selectedlog);
    setDeleteConfirmationOpen(false);
  };

  const handleAddlog = () => {
    const novalog = {
      empresa: produto.empresa,
      tipo: newlogTipo,
      quantidade : newlogQuantidade,
      dadosAdicionais: {text : newlogDadosAdicionais},
      localizacaoInterna: newlogLocalizacaoInterna,

      "codigo": produto.identificacao[0],
      "localizacao": position,
      

      
    };
  
    //createlog(novalog);
    console.log('create log ==>> ', novalog)
    setAddlogModalOpen(false);
    setNewlogEmpresa('');
    setNewlogTipo('')
    setNewlogDadosAdicionais('')
    setNewlogIdentificacao('')

  };
  

  const validateEmail = (email:any) => {
    return true
    // Implemente a lógica de validação do e-mail aqui
  };
  
  
  
  
  const maskCNPJ = (cnpj:any) => {
    // Remove caracteres não numéricos
    cnpj = cnpj.replace(/[^\d]+/g, '');
    
    // Aplica a máscara
    cnpj = cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    
    return cnpj;
  };
  
  const validatePhone = (phone:any) => {
    // Remove caracteres não numéricos
    phone = phone.replace(/[^\d]+/g, '');
    
    // Verifica se possui 11 dígitos (considerando o DDD)
    if (phone.length !== 11) {
      return false;
    }
    
    // Verifica se todos os dígitos são iguais (ex: 00000000000)
    if (/^(\d)\1+$/.test(phone)) {
      return false;
    }
    
    return true;
  };
  
  const maskPhone = (phone:any) => {
    // Remove caracteres não numéricos
    phone = phone.replace(/[^\d]+/g, '');
    
    // Aplica a máscara
    phone = phone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    
    return phone;
  };

  const handleEditlog = (log:any) => {
    console.log('log selecionada ==>> ', log)
    setEditlogData(log);
    setEditlogModalOpen(true);
  };
  const handleViewMap = (log:any) => {
    console.log('log selecionada ==>> ', log)
    setLogSelecionado(log);
    navigate('/mapa')
    //setEditlogModalOpen(true);
  };
  
  const handleUpdatelog = () => {
    console.log('editlogData',editlogData)
    //updatelog(editlogData)
    setEditlogData({});
    setEditlogModalOpen(false);
    // Lógica para atualizar a categoria com os dados do modal
  };


  

  const fetchHighlightedDays = (date: Dayjs) => {
    const controller = new AbortController();
    fakeFetch(date, {
      signal: controller.signal,
    })
      .then(({ daysToHighlight }) => {
        console.log('daysToHighlight ==>', daysToHighlight)
        setHighlightedDays(daysToHighlight);
        setIsLoading(false);
      })
      .catch((error) => {
        // ignore the error if it's caused by `controller.abort`
        if (error.name !== 'AbortError') {
          throw error;
        }
      });

    requestAbortController.current = controller;
  };


  const handleMonthChange = (date: Dayjs) => {
    if (requestAbortController.current) {
      // make sure that you are aborting useless requests
      // because it is possible to switch between months pretty quickly
      requestAbortController.current.abort();
    }

    setHighlightedDays([]);
    fetchHighlightedDays(date);
  };



  //init DataGrid dependencies

  const getCategoriaNome = (id:any)=>{
    //const nome = categories.filter((item:any)=>id == item._id)
    //return nome.length ? nome[0].nome : id

  }
  const clearData = (d:any)=>{
    const dateTransform = (moment(d).format('DD-MM-YYYY h:m'));
    return dateTransform
  }

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };
  
  const handlerSelectedItem = (item:any)=>{
    console.log('item',item)
  };

  interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
  }

  const _ = (id: GridRowId) => () => {
    setRows(rows.filter((row:any) => row._id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row:any) => row._id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row:any) => row._id !== id));
    }
  };

  const processRowUpdate:any = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    console.log('newRow',newRow)
    //setEditProdutoData(newRow)
    setRows(rows.map((row:any) => (row._id === newRow._id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };


  

/* EstoqueAtual


localizacao
: 
{timestamp: 1692306939561, mocked: false, provider: 'fused', coords: {…}} */





 

  const columns: GridColDef[] = [
    { field: '_id', headerName: 'ID', width: 90 },
    {
      field: 'createdAt',
      headerName: 'Data',
      type:'Date',
      width: 110,
      editable: false,
      valueGetter : (params: GridValueGetterParams)=> clearData(params.row.createdAt)
    },
    {
      field: 'usuario',
      headerName: 'Operador',
      type:'String',
      width: 130,
      editable: false,
      valueGetter : (params: GridValueGetterParams)=> params.row.usuario.nome
    },
    {
      field: 'tipo',
      headerName: 'Tipo',
      type:'String',
      width: 100,
      editable: false,
      //valueGetter : (params: GridValueGetterParams)=> clearData(params.row.createdAt)
    },
    {
      field: 'quantidade',
      headerName: 'Quantidade',
      type:'String',
      width: 80,
      editable: false,
      //valueGetter : (params: GridValueGetterParams)=> clearData(params.row.createdAt)
    },
  
    
    /* {
      field: 'identificacao',
      headerName: 'código',
      description: 'código do item',
      sortable: false,
      width: 140,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.identificacao[0].codigo || ''}  `,
    }, 
    {
      field: 'updatedAt',
      headerName: 'tipo identificacao',
      description: 'tipo de identificacao',
      sortable: false,
      width: 75,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.identificacao[0].tipo || ''} `,
    }, */
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Ações',
      width: 150,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        //const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const log = logs.filter((item:any)=>item._id==id)[0]
        return [
          
          <GridActionsCellItem
            icon={<Tooltip TransitionComponent={Zoom}   title="Mapa" arrow>
            <IconButton size='small' className='iconDetail' color="primary" aria-label="Editar" >
              <ViewHeadline />
            </IconButton>
          </Tooltip>}
            label="Relatório"
            className="textPrimary"
            onClick={()=> handleViewMap(log)}
            color="inherit"
          />,
          
        ];
      },
    },
    
    
  ];

 /*  const getTogglableColumns = (columns: GridColDef[]) => {
    // hide the column with field `id` from list of togglable columns
    console.log('getTogglableColumns',columns)
    return columns
      .filter((column) => column.field !== '_id')
      .map((column) => column.field);
  }; */

  const hiddenFields = ['_id','id','ID'];

const getTogglableColumns = (columns: GridColDef[]) => {
  console.log('getTogglableColumns',columns)
  return columns
    .filter((column) => !hiddenFields.includes(column.field))
    .map((column) => column.field);
};
  
const changeFilter = ()=>{

  const objToSend = {
    startDate,
    endDate,
    produto:produtoId,
    //empresa: usuario.empresa,
    //categoria:selectedCategory?._id || '',
    usuario : selectedUser?._id || '',
    
  }
  console.log('objToSend', objToSend)
  getLogsByFilter(objToSend)

}
  
  

  return (
    <Container className={classes.container}>
      <div className='{classes.root}'>
          <div className={classes.content}>
          {isLoadingLog ? (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          ) : null}
          <Button className='backbutton' onClick={() => navigate(-1)}>Voltar</Button>
          <Typography className={'title'}>
            Movimentações
          </Typography>
          {/* <Button className={classes.addButton} variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => setAddlogModalOpen(true)}>
            Gerar Movimentação
          </Button> */}
          <Typography  className={'text'} style={{marginBottom:10}}>
            Item: {produto.nome}{produto.nome != produto.identificacao[0].codigo ? " - " + produto.identificacao[0].codigo : null}
          </Typography>
          <Modal open={open} style={{width:'100%', textAlign: 'center', display: 'flex',
            alignItems : 'center', justifyContent: 'space-between',}}>
            <img width={500} style={{border:'2px solid white'}}
                    src={`${imageToOpen}?w=248&fit=crop&auto=format`}
                    srcSet={`${imageToOpen}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={''}
                    loading="lazy"
                    onClick={(evt)=>{
                      setImageToOpen('')
                      handleClick()
                    }}
                  />

          </Modal>
          <Box
            sx={{
              bgcolor: 'background.paper',
              width: 600,
              position: 'relative',
              minHeight: 200,
              margin: '0 auto'
            }}
          >
      
      
      <AppBar position="static" color="default">
      {/* <Popper open={open} anchorEl={anchorEl} placement={'top'} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} >
            <Box sx={{
              alignItems:'center',
              alignContent:'center',
              marginTop:150,
              width: 500,
              height: 400, border: 1, p: 1, bgcolor: 'background.paper' }}>
            <img width='400px' height='400px'
                    src={`${imageToOpen}?w=248&fit=crop&auto=format`}
                    srcSet={`${imageToOpen}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={''}
                    loading="lazy"
                    onClick={(evt)=>{
                      setImageToOpen('')
                      handleClick(evt)
                    }}
                  />
            </Box>
          </Fade>
        )}
      </Popper> */}
        <Box sx={{  width: '100%', height:180, }} style={{ alignSelf:'center', paddingTop:10, paddingBottom:10, borderColor:'green', borderWidth:2}}>
            <Stack   borderRadius={15} spacing={2} direction="column" alignItems={'center'} alignContent={'center'} divider={<Divider orientation="vertical" flexItem />}>
            <Box sx={{  width: '100%' }} style={{display: 'flex',flexDirection: 'row',flexWrap: 'nowrap',height:30, alignContent: 'center', justifyContent: 'space-around', alignSelf:'center', paddingTop:10, paddingBottom:10, borderColor:'green', borderWidth:2}}>
              
            <Autocomplete
                getOptionLabel={(usu:any) => usu.nome }
                //getOptionSelected={(option:any, value:any) => option.nome === value.nome }
                onChange={(event, newValue) => {
                    console.log(newValue)
                    setSelectedUser(newValue)        
                    //changeFilter()
                    
                }}
                disablePortal
                id="combo-box-usuario"
                options={usuarios}
                sx={{ width: 300 }}
                renderInput={(params:any) => <TextField {...params} label="Operador" />}
              />
            
            </Box>
            <Box sx={{  width: '100%' }} style={{display: 'flex',flexDirection: 'row',flexWrap: 'nowrap',height:70, alignContent: 'center', justifyContent: 'center', alignSelf:'center', paddingTop:10, paddingBottom:10, borderColor:'green', borderWidth:2}}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker', 'DatePicker']}>
                  <DatePicker label="Inicial" 
                          //views={["day", "month", "year"]}
                          //view={["day", "month", "year"]}
                          /* slots={{ toolbar: CustomToolbar }}
                          slotProps={{ toolbar: {} }} */
                          /* slotProps={{
                            actionBar: {
                              actions: ['clear'],
                            },
                          }} */
                          format="DD/MM/YYYY"
                          onChange={(value:any)=>{
                            if(!value) return
                            console.log('inicial',value.$d)
                            setStartDate(value.$d)
                          }} 
                        />
                  <DatePicker label="Final" 
                          format="DD/MM/YYYY"
                          onChange={(value:any)=>{
                            if(!value) return
                            const valueDate = new Date(value.$d)
                            valueDate.setHours(23)
                            valueDate.setMinutes(59)
                            valueDate.setSeconds(59)
                            //console.log('day', day)
                            console.log('final', valueDate)
                            setEndDate(valueDate)
                          }}
                        
                        />
                </DemoContainer>
              </LocalizationProvider>
              <Button className='{classes.irButton}' onClick={() => changeFilter()}>Ir</Button>
            </Box>
            
              

              

              
             
          </Stack>
          
          
          </Box> 
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          //variant="fullWidth"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="tabs em logs de produtos do sistema GreenTag"
        >
          <Tab label="Movimentações" {...a11yProps(0)} />
          <Tab label="Timeline" {...a11yProps(1)} />
          <Tab label="Calendário" {...a11yProps(2)} />
          <Tab label="Imagens" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
        {logs.length ? (
            <Box sx={{  width: '100%' }}>
              
            <DataGrid
              apiRef={apiRef}
              loading={isLoadingLog}
              getRowId={(row: { _id: any; }) => row._id}
              
              autoHeight={true}
              rows={rows}
              columns={columns}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'createdAt', sort: 'asc' }],
                },
                
                pagination: {
                  paginationModel: {
                    pageSize: 15,
                  },
                },
              }}
              pageSizeOptions={[5]}
              //checkboxSelection
              disableRowSelectionOnClick
              //editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              // slots={{
              //   toolbar: EditToolbar,
              // }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: { setRows, setRowModesModel,csvOptions:{
                  fileName: 'inventariosDataBase_',
                  delimiter: ';',
                  utf8WithBom: true,
                },
                columns: {
                  columnVisibilityModel:{ID: false,},
                },
                columnsPanel: {
                  getTogglableColumns,
                },
                printOptions: {hideToolbar:true, disableToolbarButton: false }},
              }}

              
       
              //toolbar: { printOptions: { getRowsToExport: getSelectedRowsToExport } },
        
        
            />
              
          </Box>
          ):(<div>
            Nehum registro encontrado
          </div>)}
        {/* {logs&&logs.length ? (
            <div className={classes.listContainer}>
              <List>
              {logs.map( (log: any, index: any) => (
                <ListItem key={index} className={classes.listItem}>
                  
                  <Tooltip TransitionComponent={Zoom}   title={log.dadosAdicionais?.text || ''} arrow>
                    <Typography className={classes.text}>{makeDate(log.createdAt)} - {log.EstoqueAtual} - {log.tipo}</Typography>
                  </Tooltip>
                  <Typography className={classes.address}>{logsCoords[index]}</Typography>
                  <div>
                    
                    <Tooltip TransitionComponent={Zoom}   title="Mapa" arrow>
                    
                      <IconButton className={classes.buttonOver}  aria-label="map" onClick={() => handleViewMap(log)}>
                        <MapIcon className={classes.icon} />
                      </IconButton>
                    </Tooltip>
                    
                    
                
                  </div>
                </ListItem>
              ))}
            </List>
           
            <VictoryBar
              data={logsData}
              labelComponent={
                <VictoryLabel angle={90} verticalAnchor="middle" textAnchor="start"/>
              }
            />

            </div>
            
          ) : null} */}
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
        <div className={classes.listContainer}>

              <Timeline position="alternate">
                {logs.map((item:any, i:any)=>{
                    return (<TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot color={item.tipo == 'Saida' ? "warning":"secondary"} />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent onClick={() => handleViewMap(item)} style={{}}>{makeDate(item.createdAt)} - { item.dadosAdicionais?.text|| item.tipo}</TimelineContent>
                    </TimelineItem>)
                })}
                
                
              </Timeline>
            </div>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
        <DateCalendar
              className={classes.calendar}
              disableFuture={true}
              defaultValue={initialValue}
              loading={isLoading}
              onMonthChange={handleMonthChange}
              renderLoading={() => <DayCalendarSkeleton />}
              slots={{
                day: ServerDay,
              }}
              slotProps={{
                day: {
                  highlightedDays,
                } as any,
              }}
            />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}> 
        {/* <img width='100%' height='400px'  src={slides[0].image} /> */}
          {
            slides.length ? (
             
              <ImageList sx={{ width: 500, height: 450 }} rowHeight={164} cols={3}>
              {slides.map((item:any) => (
                <ImageListItem  key={item.image} style={{width:150}}>
                  <img
                     srcSet={`${item.image}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                     src={`${item.image}?w=164&h=164&fit=crop&auto=format`}
                    
                    alt={item.text}
                    loading="lazy"
                    onClick={(evt)=>{
                      setImageToOpen(item.image)
                      handleClick()
                    }}
                  />
                  <ImageListItemBar
                    title={item.text}
                    //subtitle={<span>by: {item.author}</span>}
                    position="below"
                  />
                </ImageListItem>
              ))}
    </ImageList>
            ) : null
          }
              

        </TabPanel>
      </SwipeableViews>
      
      {/* {fabs.map((fab, index) => (
        <Zoom
          key={fab.color}
          in={value === index}
          timeout={transitionDuration}
          style={{
            transitionDelay: `${value === index ? transitionDuration.exit : 0}ms`,
          }}
          unmountOnExit
        >
          <Fab sx={fab.sx} aria-label={fab.label} color={fab.color}>
            {fab.icon}
          </Fab>
        </Zoom>
      ))} */}
    </Box>
          
          <Button className='backbutton' onClick={() => navigate(-1)}>Voltar</Button>
        </div>
      </div>
      
      <Dialog open={addlogModalOpen} onClose={() => setAddlogModalOpen(false)}>
        <DialogTitle>Adicionar log</DialogTitle>
        <DialogContent className={classes.modalContent}>
          
            <FormControl fullWidth>
              <InputLabel htmlFor="tipo-select">Tipo de movimentação</InputLabel>
              <Select
                value={newlogTipo}
                onChange={(e:any) => setNewlogTipo( e.target.value )}
                inputProps={{
                  name: 'tipo',
                  id: 'tipo-select',
                }}
              >
                {tipos.map((tipo: { _id: any; nome: any; }) => (
                  <MenuItem key={tipo._id} value={tipo._id}>
                    {tipo.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Anotação"
              value={newlogDadosAdicionais}
              onChange={(e) => setNewlogDadosAdicionais(e.target.value)}
            />
            
            <TextField
              label="Quantidade"
              value={newlogQuantidade}
              onChange={(e) => setNewlogQuantidade(e.target.value)}
            />
            <TextField
              label="Endereço"
              value={zip}
              type='text'
              onChange={(e) => {}}
            />
    
          
          
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddlogModalOpen(false)}>Cancelar</Button>
          <Button onClick={handleAddlog} color="primary">
            Criar
          </Button>
        </DialogActions>
      </Dialog>
      { editlogModalOpen && editlogData.nome ?
      <Dialog open={editlogModalOpen} onClose={() => setEditlogModalOpen(false)}>
        <DialogTitle>Editar log</DialogTitle>
        <DialogContent className={classes.modalContent}>
          <TextField
            label="Nome da log"
            value={editlogData.nome}
            onChange={(e) => setEditlogData({ ...editlogData, nome: e.target.value })}
          />
          
          <TextField
            label="Empresa"
            value={editlogData.empresa}
            onChange={(e) => setEditlogData({...editlogData, empresa : e.target.value })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Business />
                </InputAdornment>
              ),
            }}
            error={editlogData.empresa !== "" && !validatePhone(editlogData.empresa)}

            helperText={
              editlogData.empresa &&
              !validatePhone(editlogData.empresa) &&
              'Empresa inválida'
            }
            />

        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditlogModalOpen(false)}>Cancelar</Button>
          <Button onClick={handleUpdatelog} color="primary">
            Atualizar
          </Button>
        </DialogActions>
      </Dialog>:null}

      
{/* <Modal open={editlogModalOpen} onClose={() => setEditlogModalOpen(false)}>
  <div>
    <input
      type="text"
      value={editlogData.nome}
      onChange={(e) => setEditlogData({ ...editlogData, nome: e.target.value })}
    />
    <input
      type="text"
      value={editlogData.email}
      onChange={(e) => setEditlogData({ ...editlogData, email: e.target.value })}
    />
    <input
      type="text"
      value={editlogData.empresa}
      onChange={(e) => setEditlogData({ ...editlogData, empresa: e.target.value })}
    />
    <input
      type="text"
      value={editlogData.cnpj}
      onChange={(e) => setEditlogData({ ...editlogData, cnpj: e.target.value })}
    />
    <button onClick={handleUpdatelog}>Atualizar</button>
  </div>
</Modal> */}
    </Container>
  );
};

const mapStateToProps = (state: any) => ({
  logs: state.produtos.logs, 
  usuarios: state.usuarios.usuarios, 
  usuario : state.user.user,
  produtoId: state.produtos.produtoId, produto: state.produtos.produto,imagesByProduct: state.produtos.imagesByProduct , selectedImage:state.produtos.selectedImage ,empresas: state.empresas.empresas, categorias: state.categorias.categorias, isLoadingLog : state.produtos.isLoadingLogs
});

const mapDispatchToProps = {
    fetchEmpresas,
    getLogs,
    setProdutoId,
    setLogSelecionado,
    getImagesByProduct,
    getSelectedImage,
    getLogsByFilter,
    getUsuarios
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(LogsPage);
