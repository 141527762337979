import React , { useEffect, useState } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import store from './redux/store';
import HomePage from './pages/HomePage';
import UsuarioPage from './pages/UsuarioPage';
import TeacherPage from './pages/TeacherPage';
import AdminPage from './pages/AdminPage';
import InventariosPage from './pages/InventariosPage';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import './App.css';
import LoginPage from './pages/LoginPage';
import EmpresasPage from './pages/EmpresasPage';
import EmpresaPerfilPage from './pages/EmpresaPerfilPage';
import UsuariosPage from './pages/UsuariosPage';
import ProdutosPage from './pages/ProdutosPage';
import ReferenciasPage from './pages/ReferenciasPage';
import CategoriasPage from './pages/CategoriasPage';
import LogsPage from './pages/LogsPage';
import SignUpPage from './pages/SignUpPage';
import MapaPage from './pages/MapaPage';
import LocationPage from './pages/LocationPage';
import DashboardPage from './pages/Dashboard';
import NotificationsPage from './pages/NotificationsPage';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { updateUserPosition } from './redux/user/actions';
import { getProdutos, getProdutosByCategory } from './redux/produtos/actions';

import { socket } from './socket';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Admin, Resource } from 'react-admin';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Badge from '@mui/icons-material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SearchIcon from '@mui/icons-material/Search';
import MoreIcon from '@mui/icons-material/MoreVert';
import List from '@material-ui/core/List';
import Divider from '@mui/material/Divider';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button } from '@mui/material';
import { toggleDrawerStart } from './redux/global/actions';
type PropsFromRedux = ConnectedProps<typeof connector>;

const App: React.FC<PropsFromRedux> = ({ isLogged, updateUserPosition, getProdutos}) => {
//function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    //setOpen(!open);
    dispatch(toggleDrawerStart())
  };
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [fooEvents, setFooEvents] = useState<any>([]);
  //
  const createNotification = (type:any) => {
    console.log('createnotification', type)
    
      switch (type) {
        case 'info':
          NotificationManager.info('Info message');
          break;
        case 'success':
          NotificationManager.success('Success message', 'Greentag');
          break;
        case 'warning':
          NotificationManager.warning('Warning message', 'Close after 3000ms', 3000);
          break;
        case 'error':
          NotificationManager.error('Error message', 'Click me!', 5000, () => {
            alert('callback');
          });
          break;
      
    };}

    const AppBar = styled(MuiAppBar, {
      shouldForwardProp: (prop) => prop !== 'open',
    })
    <{
      open?: boolean;
    }>
    (({ theme, open }) => ({
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }),
    }));
  
  useEffect(() => {
    console.log('isLogged',isLogged)
    if(!isLogged){
      navigate('/login');
    }
  }, [isLogged]);
  

  useEffect(() => {
    function onConnect() {
      console.log('conecou no socket')
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    function onFooEvent(value:any) {
      console.log('receiveMessage', value)
      NotificationManager.success(value.message);
      if(value.type == 'produto' && value.origin == 'mobile'){
        getProdutos()
      }
      setFooEvents((previous:any) => [...previous, value.message]);
    }
    function onUpdateLocation(value:any) {
      console.log('receiveMessage onUpdateLocation', value)
      //NotificationManager.info(value.message);
      updateUserPosition(value)
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('receiveMessage', onFooEvent);
    socket.on('receiveUpdatePosition', onUpdateLocation);
    socket.connect();
    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('receiveMessage', onFooEvent);
      socket.off('receiveUpdatePosition', onUpdateLocation);
    };
  }, []);

  const drawerWidth = 240;

  return (
    <div className="app">
      <NotificationContainer/>
      <LocalizationProvider dateAdapter={AdapterDayjs}>

      
      <Header />
      
      <div className="content">
        {
          isLogged ? (<IconButton style={{height:50, width:50}}  onClick={() => toggleDrawer()}><ChevronRightIcon fontSize="inherit" /></IconButton>):null
        }
        
        {/* <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Dashboard
            </Typography>
            <IconButton color="inherit">
              <Badge  color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar> */}
        
        
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/location" element={<LocationPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/usuario" element={<UsuarioPage />} />
          <Route path="/teachers" element={<TeacherPage />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/empresas" element={<EmpresasPage />} />
          <Route path="/empresa" element={<EmpresaPerfilPage />} />
          <Route path="/usuarios" element={<UsuariosPage />} />
          <Route path="/produtos" element={<ProdutosPage />} />
          <Route path="/referencias" element={<ReferenciasPage />} />
          <Route path="/logs" element={<LogsPage />} />
          <Route path="/categorias" element={<CategoriasPage />} />
          <Route path="/mapa" element={<MapaPage />} />
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/notifications" element={<NotificationsPage />} />
          <Route path="/inventarios" element={<InventariosPage />} />
        </Routes>
      </div>

      <Footer />
      </LocalizationProvider>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  isLogged: state.auth.isLogged
  

});

const mapDispatchToProps = {
  updateUserPosition,
  getProdutos
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(App);