import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, Select, MenuItem, Collapse, Tooltip, Zoom } from '@material-ui/core';
import { Button, Container, Typography, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Modal } from '@material-ui/core';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import CloudUpload from '@material-ui/icons/CloudUpload';

import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import QrCode from '@mui/icons-material/QrCode';
import ViewHeadline from '@material-ui/icons/ViewHeadline';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import { connect, ConnectedProps } from 'react-redux';
import store from '../redux/store';
import { getUsuarios, deleteUsuario, createUsuario, updateUsuario } from '../redux/usuarios/actions';
import { getAllInventoryStart, consultProductStart, getInventoriesByCategory, getInventoriesByFilter } from '../redux/produtos/actions';
import { getCategorias,  } from '../redux/categorias/actions';
import { fetchEmpresas,  } from '../redux/empresas/actions';
import { getImagesByReferencia,  } from '../redux/referencias/actions';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import {  InputAdornment } from '@material-ui/core';
import { Email, Phone, Business, ExpandLess, ExpandMore, ViewList } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
import { Autocomplete, Divider, ListItemButton, ListSubheader, Stack } from '@mui/material';
import moment from 'moment'
import { VictoryBar, VictoryPie } from 'victory';
import Box from '@mui/material/Box';
import {
  useGridApiRef,
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbar,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridValueGetterParams,
  GridToolbarExport,
} from '@mui/x-data-grid';

import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { DefaultizedPieValueType } from '@mui/x-charts';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { serverConfig } from '../api/apiConfig';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    //alignItems: 'center',
    height: '100%',
  },
  content: {
    textAlign: 'center',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between!important',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
  },
  addButton: {
    margin: theme.spacing(1),
    backgroundColor: green[500],
    color: 'white',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    alignContent: 'center',
    flexWrap: 'nowrap',
    //alignItems: 'flex-start',
    // justifyContent: 'center',
    textAlign:'center',
    border: '1px solid green',
    width: '80%'
  },
  listContainer: {
    //maxWidth:'80%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignContent: 'center',
    justifyContent: 'flex-start',
    alignItems: 'center',
    maxHeight: 'calc(100vh - 100px)',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'green',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: 'darkgreen',
    },
  },
  irButton :{
    width:30,
    margin:10
  }
}));

const chartConfig = {
  backgroundColor: '#26872a',
  backgroundGradientFrom: '#43a047',
  backgroundGradientTo: '#66bb6a',
  color: (opacity = 1) => `#ffffff`,
  style: {
    borderRadius: 16
  }
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const InventariosPage: React.FC<PropsFromRedux> = ({ isLoadingInventory,categories, imagesByReferencia,produtos,inventories, getInventoriesByFilter,getInventoriesByCategory, getAllInventoryStart, consultProductStart , consultedProduts, isLoadingCreateUsuario, usuarios, getUsuarios, deleteUsuario, createUsuario, updateUsuario, fetchEmpresas, getCategorias, getImagesByReferencia, empresas, usuario}) => {
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  const screenWidth:any = getWindowDimensions().width
  const screenHeight:any = getWindowDimensions().height
  const classes = useStyles();
  const navigate = useNavigate();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false);
  const [selectedUsuario, setSelectedUsuario] = React.useState('');
  const [addUsuarioModalOpen, setAddUsuarioModalOpen] = React.useState(false);
  const [newUsuarioName, setNewUsuarioName] = React.useState('');
  const [newUsuarioPassword, setNewUsuarioPassword] = React.useState('');
  const [newUsuarioConfirmPassword, setNewUsuarioConfirmPassword] = React.useState('');
  const [newUsuarioEmail, setNewUsuarioEmail] = React.useState('');
  const [newUsuarioTelefone, setNewUsuarioTelefone] = React.useState('');
  const [newUsuarioEmpresa, setNewUsuarioEmpresa] = React.useState('');
  const [editUsuarioData, setEditUsuarioData] = React.useState<any>({});
  const [newUsuarioPermissoes, setNewUsuarioPermissoes] = React.useState<any>({});
  const [editUsuarioModalOpen, setEditUsuarioModalOpen] = React.useState(false);
  const [passwordOpen, setPasswordOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [category, setCategory] = React.useState('');
  const [selectedItem, setSelectedItem] = React.useState<any>({});
  const [categoriesToShow, setCategoriesToShow] = React.useState([{nome:'Todas',_id:0}]);
  
  const [total, setTotal] = React.useState<any>(0);
  const [inventoriesList, setInventoriesList] = React.useState<any>([]);
  const [tagsNotFound, setTagsNotFound] = React.useState<any>([]);
  const [tagsFound, setTtagsFound] = React.useState<any>([]);
  const [tagsLost, setTagsLost] = React.useState<any>([]);
  const [tagsEquals, setTagsEquals] = React.useState<any>([]);
  const [dataChart, setDataChart] = React.useState<any>([]);

  const [expanded, setExpanded] = React.useState<any>('tagsEquals');
  const [visible, setVisible] = React.useState(false);
  const [progress, setProgress] = React.useState(10);
  const [limit, setLimit] = React.useState(0);
  const permissoesList = ['administrador','gerente','basico']
  const [rows, setRows] = React.useState<any>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [selectedCategory, setSelectedCategory] = React.useState<any>({key:0,value:'Filtro por unidade'});
  const [selectedUser, setSelectedUser] = React.useState<any>({key:0,value:'Filtro por operador'});
  const [selectedProduto, setSelectedProduto] = React.useState<any>({key:0,value:'Filtro por produto'});
  const [startDate, setStartDate] = React.useState<any>(null);
  const [endDate, setEndDate] = React.useState<any>(null);
  
  const apiRef = useGridApiRef();

  useEffect(() => {
    setAddUsuarioModalOpen(false)
    
    getUsuarios();
  }, [getUsuarios]);

  useEffect(() => {
    changeFilter()
    
  }, [selectedUser,selectedCategory, selectedProduto, startDate, endDate]); 

  useEffect(() => {
    getCategorias()
  }, [getCategorias]);
  let timer:any
  useEffect(() => {
    console.log('limit useEffect',limit)
    setProgress(limit)
    //if(limit == 0){
      //clearInterval(timer)
      return
    //}
    
      /* timer = setInterval(() => {
        console.log('progress',progress)
        console.log('limit',limit)
         
          setProgress((prevProgress) => 
          {
            console.log('prevProgress',prevProgress)
            const inc = limit
            if(prevProgress>=limit || inc>=limit){
              console.log('limpou',timer)
              clearInterval(timer);
              timer = 0
            }
            
            return(inc >= limit ? limit : inc)
          });
      }, 100); */
    
    //return clearInterval(timer)
  }, [limit]);
  useEffect(() => {
    fetchEmpresas();
    //console.log('empresas' , empresas)
  }, [fetchEmpresas]);
  useEffect(() => {
    console.log('inventories ===>', inventories)
    if(inventories.length){
      const order = (a:any,b:any)=>{
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      }
      getImagesByReferencia(0)
      const newOrder = inventories.slice().sort(order)
      console.log('newOrder', newOrder)
      setInventoriesList(newOrder)
      setRows(newOrder)
    }
    
    //console.log('empresas' , empresas)
  }, [inventories]);
  useEffect(() => {
    getAllInventoryStart();
  }, [getAllInventoryStart]);

  useEffect(() => {
    if(isLoadingInventory){
      setLoading(true)
    }
    if(loading && !isLoadingInventory){
      //getUsuarios();
      setLoading(false)
      //apiRef.current.setColumnVisibility('ID', false);
    }
    console.log('isLoadingInventory ==> ',isLoadingInventory)
    
  }, [isLoadingInventory]);
  useEffect(() => {
      if(!isLoadingInventory&&inventoriesList.length){
        
        apiRef.current.setColumnVisibility('_id', false);
      }
  }, [inventoriesList]);

  useEffect(() => {
    if(isLoadingCreateUsuario){
      setLoading(true)
    }
    if(loading && !isLoadingCreateUsuario){
      getUsuarios();
      setLoading(false)
    }
    console.log('isLoadingCreateUsuario ==> ',isLoadingCreateUsuario)
    
  }, [isLoadingCreateUsuario]);

  const handleDeleteClick = (UsuarioId: string) => {
    console.log('UsuarioId',UsuarioId)
    setSelectedUsuario(UsuarioId);
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirmation = () => {
    console.log('selectedUsuario',selectedUsuario)
    deleteUsuario(selectedUsuario);
    setDeleteConfirmationOpen(false);
  };


  const makePermissoes = ()=>{
    if(newUsuarioPermissoes == 'administrador'){
      return {admin : true}
    } else if(newUsuarioPermissoes == 'basico'){
      return {basic : true}
    } else if(newUsuarioPermissoes == 'gerente'){
      return {manager : true}
    } else{
      return {}
    }
  }

 
  

  

  const handlerSelectedItem = (item:any)=>{
    console.log(item, item)
    setSelectedItem(item)

    
    item.conteudo.tagsFound.map((el:any)=>{
      let tipo = el.length == 8 ? 'EAN_8' : "RFID";
      if(el.length == 13){
        tipo= 'EAN_13'
      }
       
      const dataConsulting = 
      {
        "tipo": tipo,
        "codigo": el
      }
      setTimeout(()=> consultProductStart(dataConsulting),500)
      
    })
    item.conteudo.tagsFound.map((el:any)=>{
      let tipo = el.length == 8 ? 'EAN_8' : "RFID";
      if(el.length == 13){
        tipo= 'EAN_13'
      }
       
      const dataConsulting = 
      {
        "tipo": tipo,
        "codigo": el
      }
      setTimeout(()=> consultProductStart(dataConsulting),500)
      
    })
    item.conteudo.tagsNotFound.map((el:any)=>{
      let tipo = el.length == 8 ? 'EAN_8' : "RFID";
      if(el.length == 13){
        tipo= 'EAN_13'
      }
       
      const dataConsulting = 
      {
        "tipo": tipo,
        "codigo": el
      }
      setTimeout(()=> consultProductStart(dataConsulting),500)
      
    })
    const newLimit=(item.conteudo.progress||1)*100
    
    clearInterval(timer);
    setLimit(newLimit)
    setProgress(0)

  

    setTagsLost(item.conteudo.tagsLost)
    setTagsEquals(item.conteudo.tagsEquals)
    //console.log('tagsNotFoundList ===>  ',tagsNotFoundList)
    setTagsNotFound(item.conteudo.tagsNotFound)
    setTtagsFound(item.conteudo.tagsFound)
    setTotal(item.conteudo.tagsFound.length)
    const newData = [
      {
        label: "reconhecidos",
        id:1,
        value: item.conteudo.tagsEquals.length,
        //color: "green",
        // legendFontColor: "#7F7F7F",
        // legendFontSize: 15
      },
      {
        
        label: "faltantes",
        id:2,
        value: item.conteudo.tagsLost.length,
        color: "red",
        // legendFontColor: "#7F7F7F",
        // legendFontSize: 15
      },
      {
        label: "extra",
        id:3,
        value: item.conteudo.tagsNotFound.length,
        //color: "yellow",
        // legendFontColor: "#7F7F7F",
        // legendFontSize: 15
      }
    ]
    setDataChart(newData)
    setVisible(true)
  }

  const getCategoriaNome = (id:any)=>{
    const nome = categories.filter((item:any)=>id == item._id)
    return nome.length ? nome[0].nome : id

  }
  const getProdutoNome = (id:any)=>{
    const nome = produtos.filter((item:any)=>id == item._id)
    return nome.length ? nome[0].nome : id

  }

  

  const clearData = (d:any)=>{
    const dateTransform = (moment(d).format('DD-MM-YYYY h:m'));
    return dateTransform
  }


  const checkItem = (item:any)=>{
    let achou:any = false
    //console.log(item)
    for(var i in consultedProduts){
      //console.log('i checkItem',i)
      /* const search = tagsFound.filter((item:any)=>{
        item._id == i
      })
      search.length ? achou = true : null */
      if(i == item) achou = consultedProduts[i]
    }
    //console.log("achou ==>> ",achou)
    return achou
      
      
  }

  interface Props {
    title:any
    subTitle:any
    itensList:[]
    type:any
  }


  function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
  ) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }

  const ImageComponent = (obj: any)=>{
    console.log('ImageComponent',obj)
    //console.log('editados',editados)
    //setEditReferenciaData(obj.obj.row)
    let caminho:any = '';
    let image:any = '';
    let referencia = obj.obj?.referencia ||''
    if( referencia&&imagesByReferencia?.length){
      caminho = imagesByReferencia.filter((item:any)=> referencia == item.idReferencia)[0]
      //console.log(caminho)
      if(caminho){
        image = serverConfig.dev.url + 'imagens/imagem/'+ caminho._id;
        return (
          <ListItemAvatar>
            <Avatar sx={{ width: 48, height: 48 }} variant='rounded' src={image}></Avatar>
          </ListItemAvatar>
          
           /*  {
              obj.obj?.nome ? (
                <Avatar.Accessory name='verified' backgroundColor={'white'} type='materialIcons' color={'#008800'}  size={20} />
              ) : (
                <Avatar.Accessory name='backup' backgroundColor={'white'} type='materialIcons' color={'#008800'}  size={20} />
              )
            } */
                  
             
          
        )
        //console.log(image)
        
      }else{
        return (
          // <Avatar  icon={  { name: 'report-off', type:'materialIcons', color:'#008800', size:30, } } >
          //   <Avatar.Accessory backgroundColor={'white'} color={'#008800'} name='verified' type='materialIcons'  size={20}  />
          // </Avatar>
          <ListItemAvatar>
            <Avatar sx={{ width: 56, height: 56 }} variant='rounded' ><ImageIcon></ImageIcon></Avatar>
          </ListItemAvatar>
          
         
        )
      }
      
    }else{ 
      {/* <IconButton onClick={
          ()=>{
            //handleEditReferencia(obj.obj.row)
            }
          }>
          <ImageSearch />
        </IconButton> */}
      return(
        <ListItemAvatar>
            <Avatar><ImageIcon></ImageIcon></Avatar>
          </ListItemAvatar>
       
      )
    }
    
  }


  const RenderItem = (props: Props)=>{
    const { title, subTitle, itensList,type } = props // or props.[YOUR PROPS] to access your named props
    //console.log('itensList',itensList)
    return(
      <>

      <ListItemButton onClick={()=>{
        setExpanded(expanded!=type?type:false);
      }}>
        {/* <ListItemIcon>
          <InboxIcon />
        </ListItemIcon> */}

        <ListItemText primary={itensList.length + ' - '+title} secondary={subTitle} />
        {expanded==type ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

        <Collapse in={expanded==type} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          {
          itensList.length ? (
          
            
              itensList.map((el:any)=>{
                console.log('el',el)
                const item = checkItem(el)
                return(
                  
                  <ListItemButton sx={{ pl: 4 }}>
                  {/* <ListItemIcon>
                        <StarBorder />
                      </ListItemIcon> */}
                      {/* {item?.categoria ? (<ListItem.Subtitle style={{fontSize:14}} >unidade: {getCategoriaNome(item.categoria)}
                      </ListItem.Subtitle>):null} */}
                    <ImageComponent obj={item || el} />
                    <ListItemText style={{color:'#008800', fontSize:18}} primary={item?.nome || el} secondary={item?.categoria ? ('unidade: '+ getCategoriaNome(item.categoria)):'desconhecido'} />
                    <Divider />
                    
                  </ListItemButton>
                  
                 
                )
              })
            
        ) : null
      }
            
          </List>
        </Collapse>
        
      </>
    )
  }
  
  
  const handlerCloseModal = ()=>{
    setVisible(false)
    setLimit(0)
    setProgress(0)
    clearInterval(timer);
    setExpanded('tagsEquals')
  }

  const compare = (a:any,b:any)=>{
    if(a.createdAt > b.createdAt)
      return -1
    else if(a.createdAt < b.createdAt)
      return 1
    else
      return 0

  }


  const changeCategoria = (cat:any)=>{
    
    console.log('categoria id: ',cat)
    
    if (cat == 0){
      getAllInventoryStart()
    }else{
      //get(cat)
    }
    
  }

 
  //init DataGrid dependencies

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
  }

  const _ = (id: GridRowId) => () => {
    setRows(rows.filter((row:any) => row._id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row:any) => row._id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row:any) => row._id !== id));
    }
  };

  const processRowUpdate:any = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    console.log('newRow',newRow)
    //setEditProdutoData(newRow)
    setRows(rows.map((row:any) => (row._id === newRow._id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
 

  const columns: GridColDef[] = [
    { field: '_id', headerName: 'ID', width: 90 },
    {
      field: 'createdAt',
      headerName: 'Data',
      type:'Date',
      width: 120,
      editable: false,
      valueGetter : (params: GridValueGetterParams)=> clearData(params.row.createdAt)
    },
    {
      field: 'categoria',
      headerName: 'Unidade',
      width: 120,
      editable: false,
      valueGetter : (params: GridValueGetterParams)=> getCategoriaNome(params.row.categoria)
    },
    {
      field: 'usuario',
      headerName: 'Operador',
      width: 120,
      editable: false,
      valueGetter : (params: GridValueGetterParams)=> params.row.usuario?.nome
    },
    
    {
      field: 'quantidade',
      headerName: 'Quantidade',
      type: 'number',
      width: 90,
      editable: false,
      valueGetter : (params: GridValueGetterParams)=> params.row.conteudo.productsByCategory.length   
    },
    {
      field: 'resultado',
      headerName: 'Resultado',
      type: 'number',
      width: 90,
      editable: false,
      valueGetter : (params: GridValueGetterParams)=> (Math.round((params.row.conteudo.progress || 1)  * 100) )+'%'
    },
    
    {
      field: 'identificados',
      headerName: 'Identificados',
      type: 'number',
      width: 90,
      editable: false,
      valueGetter : (params: GridValueGetterParams)=> ((params.row.conteudo.tagsEquals.length || 1) )
    },
    {
      field: 'tagsLost',
      headerName: 'Nâo encontrados',
      type: 'number',
      width: 90,
      editable: false,
      valueGetter : (params: GridValueGetterParams)=> ((params.row.conteudo.tagsLost.length || 0) )
    },
    {
      field: 'notfound',
      headerName: 'Externos',
      type: 'number',
      width: 80,
      editable: false,
      valueGetter : (params: GridValueGetterParams)=> ((params.row.conteudo.tagsNotFound.length || 0) )
    },
    {
      field: 'lidos',
      headerName: 'Lidos',
      type: 'number',
      width: 80,
      editable: false,
      valueGetter : (params: GridValueGetterParams)=> ((params.row.conteudo.tagsFound.length || 1) )
    },
    /* {
      field: 'identificacao',
      headerName: 'código',
      description: 'código do item',
      sortable: false,
      width: 140,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.identificacao[0].codigo || ''}  `,
    }, 
    {
      field: 'updatedAt',
      headerName: 'tipo identificacao',
      description: 'tipo de identificacao',
      sortable: false,
      width: 75,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.identificacao[0].tipo || ''} `,
    }, */
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Ações',
      width: 150,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        //const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const inventory = inventoriesList.filter((item:any)=>item._id==id)[0]
        return [
          
          <GridActionsCellItem
            icon={<Tooltip TransitionComponent={Zoom}   title="Relatório" arrow>
            <IconButton className='iconDetail' color="primary" aria-label="Relatório" >
              <ViewHeadline />
            </IconButton>
          </Tooltip>}
            label="Relatório"
            className="textPrimary"
            onClick={()=>handlerSelectedItem(inventory)}
            color="inherit"
          />,
          
        ];
      },
    },
    
    
  ];

  const hiddenFields = ['_id','id','ID'];

const getTogglableColumns = (columns: GridColDef[]) => {
  console.log('getTogglableColumns',columns)
  return columns
    .filter((column) => !hiddenFields.includes(column.field))
    .map((column) => column.field);
};

interface AutocompleteOption {
  categoria: string;
}

const changeFilter = ()=>{

  const objToSend = {
    startDate,
    endDate,
    empresa: usuario.empresa,
    categoria:selectedCategory?._id || '',
    usuario : selectedUser?._id || '',
    produto : selectedProduto?._id || '',
  }
  console.log('objToSend', objToSend)
  getInventoriesByFilter(objToSend)

}

const CustomToolbar = () => (
  <input value={'limpar'} onChange={(event:any) => setNewUsuarioName(event.target.value)} />
);

  return (
    <Container className={classes.container}>
      <div className={classes.root}>
          <div className={classes.content}>
          <Button className='backbutton' onClick={() => navigate(-1)}>Voltar</Button>
          <Typography variant="h4" className={'title'}>
            Inventários
          </Typography>

          {inventoriesList.length?(
            <div className={'text'} style={{margin:15}}>
              {inventoriesList.length} Itens encontrados
            </div>
          ):null}

          {/* <FormControl fullWidth>
              <InputLabel className={'text'} htmlFor="tipo-select">Itens por Unidade</InputLabel>
              <Select
                className={classes.select}
                value={selectedCategoria}
                onChange={(e:any) => {

                  setSelectedCategoria( e.target.value )
                  changeCategoria( e.target.value)
                  
                }}
                inputProps={{
                  name: 'categoria',
                  id: 'tipo-select',
                }}
              >
                {categoriasToShow.map((cat: { _id: any; nome: any; }) => (
                  <MenuItem className={'text'} key={cat._id} value={cat._id}>
                    {cat.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          
          <Box sx={{  width: '100%', height:180, }} style={{ alignSelf:'center', paddingTop:10, paddingBottom:10, borderColor:'green', borderWidth:2}}>
            <Stack   borderRadius={15} spacing={2} direction="column" alignItems={'center'} alignContent={'center'} divider={<Divider orientation="vertical" flexItem />}>
            <Box sx={{  width: '100%' }} style={{display: 'flex',flexDirection: 'row',flexWrap: 'nowrap',height:30, alignContent: 'center', justifyContent: 'space-around', alignSelf:'center', paddingTop:10, paddingBottom:10, borderColor:'green', borderWidth:2}}>
              <Autocomplete
                getOptionLabel={(cat:any) => getCategoriaNome(cat.nome) }
                //getOptionSelected={(option:any, value:any) => option.nome === value.nome }
                onChange={(event, newValue) => {
                    console.log(newValue)
                    setSelectedCategory(newValue)
                    if(!newValue){
                      
                      getAllInventoryStart()
                      return
                    }
                    
                    getInventoriesByCategory(newValue._id)
                }}
                disablePortal
                id="combo-box-demo"
                options={categories}
                sx={{ width: 300 }}
                renderInput={(params:any) => <TextField {...params} label="Unidades" />}
              />
            <Autocomplete
                getOptionLabel={(usu:any) => usu.nome }
                //getOptionSelected={(option:any, value:any) => option.nome === value.nome }
                onChange={(event, newValue) => {
                    console.log(newValue)
                    setSelectedUser(newValue)        
                    //changeFilter()
                    
                }}
                disablePortal
                id="combo-box-usuario"
                options={usuarios}
                sx={{ width: 300 }}
                renderInput={(params:any) => <TextField {...params} label="Operador" />}
              />
            <Autocomplete
                getOptionLabel={(prod:any) => getProdutoNome(prod.nome) }
                //getOptionSelected={(option:any, value:any) => option.nome === value.nome }
                onChange={(event, newValue) => {
                    console.log(newValue)
                    setSelectedProduto(newValue)
                   /*  if(!newValue){
                      
                      //getAllInventoryStart()
                      return
                    } */
                    //changeFilter()
                    
                    //getInventoriesByCategory(newValue._id)
                }}
                disablePortal
                id="combo-box-produtos"
                options={produtos}
                sx={{ width: 300 }}
                renderInput={(params:any) => <TextField {...params} label="Produtos" />}
              />
            </Box>
            <Box sx={{  width: '100%' }} style={{display: 'flex',flexDirection: 'row',flexWrap: 'nowrap',height:70, alignContent: 'center', justifyContent: 'center', alignSelf:'center', paddingTop:10, paddingBottom:10, borderColor:'green', borderWidth:2}}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker', 'DatePicker']}>
                  <DatePicker label="Inicial" 
                          //views={["day", "month", "year"]}
                          //view={["day", "month", "year"]}
                          /* slots={{ toolbar: CustomToolbar }}
                          slotProps={{ toolbar: {} }} */
                          /* slotProps={{
                            actionBar: {
                              actions: ['clear'],
                            },
                          }} */
                          format="DD/MM/YYYY"
                          onChange={(value:any)=>{
                            if(!value) return
                            console.log('inicial',value.$d)
                            setStartDate(value.$d)
                          }} 
                        />
                  <DatePicker label="Final" 
                          format="DD/MM/YYYY"
                          onChange={(value:any)=>{
                            if(!value) return
                            const valueDate = new Date(value.$d)
                            valueDate.setHours(23)
                            valueDate.setMinutes(59)
                            valueDate.setSeconds(59)
                            //console.log('day', day)
                            console.log('final', valueDate)
                            setEndDate(valueDate)
                          }}
                        
                        />
                </DemoContainer>
              </LocalizationProvider>
              <Button className='{classes.irButton}' onClick={() => changeFilter()}>Ir</Button>
            </Box>
            
              

              

              
             
          </Stack>
          
          
          </Box> 
          {rows.length ? (
            <Box sx={{  width: '100%' }}>
              
            <DataGrid
              apiRef={apiRef}
              loading={isLoadingInventory}
              getRowId={(row: { _id: any; }) => row._id}
              //checkboxSelection
          disableRowSelectionOnClick
          
          onClipboardCopy={(copiedString) => console.log(copiedString)}
          unstable_ignoreValueFormatterDuringExport
              autoHeight={true}
              rows={rows}
              columns={columns}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'createdAt', sort: 'desc' }],
                },
                
                pagination: {
                  paginationModel: {
                    pageSize: 15,
                  },
                },
              }}
              pageSizeOptions={[5]}
              //checkboxSelection
              
              //editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              // slots={{
              //   toolbar: EditToolbar,
              // }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: { setRows, setRowModesModel,csvOptions:{
                  fileName: 'inventariosDataBase_',
                  delimiter: ';',
                  utf8WithBom: true,
                },
                columns: {
                  columnVisibilityModel:{ID: false,},
                },
                columnsPanel: {
                  getTogglableColumns,
                },
                printOptions: {hideToolbar:true, disableToolbarButton: false }},
              }}

              
       
              //toolbar: { printOptions: { getRowsToExport: getSelectedRowsToExport } },
        
        
            />
              
          </Box>
          ):(<div style={{margin:15}}>
            Nehum registro encontrado
          </div>)}
          

          {/* {inventories.length ? (
            <div className={'listContainer'}>
              <List>
                {inventories.map((inventory: any, index: any) => (
                  <ListItem key={index} className={classes.listItem} divider>
                    <ListItemText
                    
                    primaryTypographyProps={{color:'#008800'}}
                    primary={getCategoriaNome(inventory.categoria)}
                    secondary={clearData(inventory.createdAt)}
                  />
                    
                    
                    <div>
                      <IconButton color="primary" aria-label="Editar" onClick={() => handlerSelectedItem(inventory)}>
                        <ViewList  />
                      </IconButton>
                      
                     
                    </div>
                  </ListItem>
                ))}
              </List>
            </div>
            
          ) : null} */}
          <Button className='backbutton' onClick={() => navigate(-1)}>Voltar</Button>
        </div>
      </div>

      <Dialog open={deleteConfirmationOpen} onClose={() => setDeleteConfirmationOpen(false)}>
        <DialogTitle>Confirmar exclusão</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Tem certeza de que deseja excluir?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmationOpen(false)}>Cancelar</Button>
          <Button onClick={handleDeleteConfirmation} color="secondary">
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
      

      <Modal className={classes.modalContent}  open={visible}  onClose={() => handlerCloseModal()}   >
      
            <div style={{margin:0, backgroundColor:'white', width:screenWidth}} className={classes.modalContent}>
              
              

              <Button className='backbutton' onClick={() => handlerCloseModal()}>Voltar</Button>
              
              <div className={classes.listContainer}>
              <div style={{margin:15, }}>
                  <div >
                  <Stack   borderRadius={15} spacing={2} direction="row" alignItems={'center'} alignContent={'center'} divider={<Divider orientation="vertical" flexItem />}>
                    <div style={{fontSize:18, margin:15}}>Unidade: {getCategoriaNome(selectedItem?.categoria)}</div>
                      
                      <div style={{fontSize:18, margin:15}}>Itens na unidade: {selectedItem?.conteudo?.productsByCategory.length}</div>
                      <div style={{fontSize:18, margin:15}}>Operador: {selectedItem?.usuario?.nome}</div>
                  
                  </Stack>
                    <Divider />
                    {/* <div style={{}}>Itens lidos: {tagsFound.length} </div> */}
                    {limit ? (<CircularProgressWithLabel style={{margin:15}} value={progress}/>)  : null}
                    
                    <RenderItem title={'Itens lidos'} subTitle='clique para expandir' type={'tagsFound'} itensList={tagsFound} />
                    
                    <RenderItem title={'Itens reconhecidos'} subTitle='clique para expandir' type={'tagsEquals'} itensList={tagsEquals} />             
                    <RenderItem title={'Itens não encontrados'} subTitle='clique para expandir' type={'tagsLost'} itensList={tagsLost} />             
                    <RenderItem title={'Itens de outras unidades'} subTitle='clique para expandir' type={'tagsNotFound'} itensList={tagsNotFound} />             
                    <div style={{alignSelf:'center', marginTop:15}}>
                    <PieChart 
                      series={[
                        {
                          data: dataChart
                        },
                      ]}
                      width={400}
                      height={200}
                    />
                    </div>
                    
                    {/* <VictoryPie
                      width={450}
                      height={380}
                      data={dataChart}
                      labels={({ datum }) => `${datum.y}: ${datum.label}`}
                      colorScale={["green", "red", "gold" ]}
                      animate={{
                        duration: 2000
                      }}
                      style={{
                        data: {
                          fillOpacity: 0.9, stroke: "#0af253", strokeWidth: 2, 
                        },
                        labels: {
                          fontSize: 14,  marginLeft:-10
                        }
                      }}
                    /> */}
                    
                  </div>
                  
                  
                  {
                    /* tagsNotFound&&tagsNotFound.length ? (
                      <div >
                        {tagsNotFound.map((item: any, i: any) => {
                          return (
                            <ListItem key={i} onPress={(log) => {  }} bottomDivider>
                              <ListItem.Content>
                                <ListItem.Title style={{color: '#e8ca74'}}>{item}</ListItem.Title>
                                
                              </ListItem.Content>
                            </ListItem>

                          )

                        })}
                        
                      </div>
                      
                    ) : null */
                  
                  }
                  
                </div>
                {/* <PieChart
                          data={dataChart}
                          width={screenWidth}
                          height={200}
                          chartConfig={chartConfig}
                          accessor={"quantidade"}
                          backgroundColor={"transparent"}
                          paddingLeft={"-12"}
                          
                          center={[17, 0]}
                          absolute
                          
                        /> */}
                        {/* <Image style={styles.image} source={require("./../../assets/png/logo_color_no_bg.png")}/> */}
              
             

              </div>
                
              {/* <div>
                //button to trigger printing of target component 
                <ReactToPrint
                  trigger={() => <Button>Print this out!</Button>}
                  content={() => componentRef}
                />

                
                <ComponentToPrint ref={(el) => (componentRef = el)} />
              </div> */}

            </div>
          </Modal>
      

      
{/* <Modal open={editUsuarioModalOpen} onClose={() => setEditUsuarioModalOpen(false)}>
  <div>
    <input
      type="text"
      value={editUsuarioData.nome}
      onChange={(e) => setEditUsuarioData({ ...editUsuarioData, nome: e.target.value })}
    />
    <input
      type="text"
      value={editUsuarioData.email}
      onChange={(e) => setEditUsuarioData({ ...editUsuarioData, email: e.target.value })}
    />
    <input
      type="text"
      value={editUsuarioData.telefone}
      onChange={(e) => setEditUsuarioData({ ...editUsuarioData, telefone: e.target.value })}
    />
    <input
      type="text"
      value={editUsuarioData.cnpj}
      onChange={(e) => setEditUsuarioData({ ...editUsuarioData, cnpj: e.target.value })}
    />
    <button onClick={handleUpdateUsuario}>Atualizar</button>
  </div>
</Modal> */}
    </Container>
  );
};

const mapStateToProps = (state: any) => ({
  usuarios: state.usuarios.usuarios, 
  usuario : state.user.user,
  isLoadingInventory : state.produtos.isLoadingInventory,
  categories : state.categorias.categorias,
  consultedProduts: state.produtos.consultedProduts,
  produtos: state.produtos.produtos,
  inventories: state.produtos.inventories,
  imagesByReferencia: state.referencias.imagesByReferencia,
  isLoadingCreateUsuario : state.usuarios.isLoadingCreateUsuario,
  empresas: state.empresas.empresas
});

const mapDispatchToProps = {
  fetchEmpresas,
  getCategorias,
  getUsuarios,
  deleteUsuario,
  createUsuario,
  updateUsuario,
  getAllInventoryStart,
  getInventoriesByCategory,
  getInventoriesByFilter,
  consultProductStart,
  getImagesByReferencia,

};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(InventariosPage);
